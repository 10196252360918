import { ITotaltable } from '@interfaces/ITotaltable';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-cashin',
  templateUrl: './cashin.component.html',
  styleUrls: ['./cashin.component.scss']
})
export class CashinComponent implements OnInit {
  public params
  public allOperationsCashInData: any;
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Recargas';
  private from_date = null;
  private to_date = null;
  private mcode = null;
  private dataFilters = {}
  public totaltable: ITotaltable = {
    countRow : 0,
    currency: '',
    totalCOP: 0,
  };

  constructor(private api: ApiService, private router: Router, private currencyPipe: CurrencyPipe, private route: ActivatedRoute) {
    
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.from_date = params.from_date ? params.from_date : null;
      this.to_date = params.to_date ? params.to_date : null;
      this.mcode = params.m_code ? params.m_code : null;
    });

    this.allOperationsCashInData = this.allOperationsCashIn();
  }

  async allOperationsCashIn(dataInfo: any = false) {
    console.log(this.from_date);
    
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'cashin/operations',
      data: {
        filters: {
          MerchantType: 6,
          from_date: this.from_date,
          to_date: this.to_date,
          mcode: this.mcode
        }
      }
    };

    const headers = [
      {
        'name': 'Operación',
        'key': 'PublicId',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Proveedor',
        'key': 'ProviderName',
        'opc': true
      },
      {
        'name': 'Servicio',
        'key': 'ServiceDescription',
        'opc': true
      },
      {
        'name': 'Shopper',
        'key': 'DocNumber',
        'opc': true
      },
      {
        'name': 'ShopperName',
        'key': 'FullName',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      },
      {
        name: "Metadata",
        key: "Metadata",
        opc: true
      },
      {
       'name': 'Actividades',
       'key': 'actividades',
       'opc': true
      }
    ];


    if (dataInfo !== false) {
      data.service = 'listAllOperationCashinWithParams';
      dataInfo.MerchantType = 6;
      data.data = dataInfo;
    }

    const getData = await this.api.api(data).toPromise() as any[];
    
/*                                                                    */

    for (var i in getData) {
      // getData[i].Amount = new Intl.NumberFormat("en-US").format(getData[i].Amount);

      // getData[i].Amount = this.currencyPipe.transform(Number(getData[i].Amount), 'COP').replace(',', '.').replace('COP', '')
      // getData[i].Amount 
      // console.log('Moneda: ',  getData[i].Amount);
    } 
    
    // let conMoneda = getData.map( monto => monto.Amount);
    // console.log('Moneda: ',  getData[i].Amount);

    this.totaltable = {
      countRow : 0,
      currency: '',
      totalCOP: 0,
    };
    if(getData && getData.length) {
      getData.forEach((item, i) => {
        let laguage = 'es-CO';
  
        if (item.CurrencyCode === 'COP') {
          this.totaltable.totalCOP += Number(String(item.Amount).replace(',', '.'));
        }
        const formatter = new Intl.NumberFormat(laguage, {
          style: 'decimal',
          currency: item.CurrencyCode,
          maximumFractionDigits: 0,
        });
        getData[i].Amount = this.currencyPipe.transform(Number(getData[i].Amount), 'COP').replace(',', '.').replace('COP', '')
        // getData[i].Amount = formatter.format(Number(String(item.Amount).replace(',', '.')));
        // result[i].Amount = Number(9000000.01).toLocaleString('es', {maximumFractionDigits: 0,  minimumFractionDigits: 0});
      });
      this.totaltable.currency = 'COP';
      this.totaltable.countRow = getData.length;
    }
console.log('getData', getData)
    return {
      headers: headers,
      data: getData,
      source: 'operation-cashin'
    };

  }



  async getFilters(){

    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    };

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach((element, key) => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
          selected: key == 0 ? true : false
        });

      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    const dataProv = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'provider/balance'
    };

    const dataStatus = this.api.getStatus();

    const dataProvider = [];
    await this.api.api(dataProv).toPromise().then((resultp: any) => {
      resultp.forEach(element => {
        dataProvider.push({
          name: element.BussinesName,
          value: element.ProviderCode,
        });
      });    
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    
    const dataService = [];

    return [
      // {
      //   'name': 'Código de pago',
      //   'key': 'paymentCode',
      //   'type': 'text'
      // },      
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'Referencia Comercial',
        'key': 'merchantSalesID',
        'type': 'text'
      },
      {
        'name': 'Proveedores', //newfilter
        'key': 'providerCode',
        'type': 'select',
        'data': dataProvider
      },
      {
        'name': 'Documento del cliente',//New Filter
        'key': 'documentClient',
        'type': 'text',
        'data': dataProvider
      },
      {
        'name': 'Periodo',
        'key': ['from_date','to_date'],
        'type': 'period'
      },
      {
        'name': 'Servicio',
        'key': 'serviceCode',
        'type': 'select',
        'data': dataService
      },
      {
        'name': 'N° Operación',
        'key': 'PublicId',
        'type': 'text'
      }
    ];

  }

  changeDataTable(data) {
    this.dataFilters = {
      ...this.dataFilters,
      ...data
    }
    this.allOperationsCashInData = this.allOperationsCashIn(this.dataFilters);
  }

}
