import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
3;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  urlCore = environment.api;

  constructor(private http: HttpClient, public router: Router) {}
  api(datos: any) {
    switch (datos.service) {
      case "auth/login":
        console.log("DATOS", datos, this.urlCore, datos.service);
        return this.http.post(`${this.urlCore + datos.service}`, datos);
      default:
        break;
    }
  }
}
