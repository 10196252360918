import { HttpParams } from "@angular/common/http";
import { HttpService } from "./../http.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MerchantService {
  constructor(private http: HttpService) {}

  getMerchants(MerchantType = "6") {
    let merchants = [];
    if ((merchants = JSON.parse(localStorage.getItem("merchants")))) {
      return of (merchants);
    }

    let params = new HttpParams();
    params = params.append("MerchantType", MerchantType);
    params = params.append("CurrencyCode", 'COP');
    return this.http.get(`merchant`, null, params).pipe(
      map((data) => {
        localStorage.setItem("merchants", JSON.stringify(data));
        return data;
      })
    );
  }
}
