import { ITotaltable } from "@interfaces/ITotaltable";
import { SpinnerService } from "@services/spinnerServices/spinner.service";
import { Router } from "@angular/router";
import { ApiService } from "@services/api.service";
import { Component, OnInit } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: "app-operations",
  templateUrl: "./operations.component.html",
  styleUrls: ["./operations.component.scss"],
})
export class OperationsComponent implements OnInit {
  public allOperationsCashInData: any = this.allOperationsCashIn();
  public filters: any = this.getFilters();
  public titlePage: string = "Reportes > Operaciones";
  private dataFilters = {}
  public totaltable: ITotaltable = {
    countRow: 0,
    currency: "",
    totalCOP: 0,
  };

  constructor(
    private api: ApiService,
    private router: Router,
    private spinnerService: SpinnerService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    $("#charging").addClass("hide");
  }

  async allOperationsCashIn(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "cashin/operations",
      data: {
        filters: {
          MerchantType: 6
        }
      },
    };

    const headers = [
      {
        name: "Operación",
        key: "PublicId",
        opc: true,
      },
      {
        name: "Monto",
        key: "Amount",
        opc: true,
      },
      // {
      //   'name': 'Moneda',
      //   'key': 'CurrencyCode',
      //   'opc': true
      // },
      {
        name: "Cliente",
        key: "BussinesName",
        opc: true,
      },
      {
        name: "Código de pago",
        key: "PaymentCode",
        opc: true,
      },
      {
        name: "Creación",
        key: "TxCreation",
        opc: true,
      },
      {
        name: "Expiración",
        key: "TxExpiration",
        opc: true,
      },
      {
        name: "Estado",
        key: "LastStatus",
        opc: true,
      },
      {
        name: "Metadata",
        key: "Metadata",
        opc: true
      },
      {
        name: "Actividades",
        key: "actividadrcoperations",
        opc: "true",
      },
    ];
    if (dataInfo !== false) {
      data.service = "listAllOperationCashinWithParams";
      dataInfo["MerchantType"] = 6;
      data.data = dataInfo;
    }
    const getData = (await this.api.api(data).toPromise()) as any[];
    /*for (var i in getData) {
      // getData[i].Amount = new Intl.NumberFormat("en-US").format(getData[i].Amount);
    } */

    this.totaltable = {
      countRow: 0,
      currency: "",
      totalCOP: 0,
    };
    if(getData && getData.length) {
      getData.forEach((item, i) => {
        let laguage = "es-CO";
        if (item.CurrencyCode === "COP") {
          this.totaltable.totalCOP += Number(
            String(item.Amount).replace(",", ".")
          );
        }
        const formatter = new Intl.NumberFormat(laguage, {
          style: "decimal",
          currency: item.CurrencyCode,
          minimumFractionDigits: 2,
        });
        getData[i].Amount = this.currencyPipe
          .transform(Number(getData[i].Amount), "COP")
          .replace(",", ".");
          
        // getData[i].Amount = formatter.format(Number(String(item.Amount).replace(',', '.')));
        // result[i].Amount = Number(9000000.01).toLocaleString('es', {maximumFractionDigits: 0,  minimumFractionDigits: 0});
      });
      this.totaltable.currency = "COP";
      this.totaltable.countRow = getData.length;
    }
    
    return {
      headers: headers,
      data: getData,
      source: "operation-cashin",
    };
  }

  async getFilters() {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "merchants",
    };

    const dataProv = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "provider/balance",
    };

    const dataStatus = this.api.getStatus();

    //
    const dataProvider = [];
    await this.api
      .api(dataProv)
      .toPromise()
      .then((resultp: any) => {
        resultp.forEach((element) => {
          dataProvider.push({
            name: element.BussinesName,
            value: element.ProviderCode,
          });
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
        }
      });

    //
    const dataMerchant = [];
    await this.api
      .api(data)
      .toPromise()
      .then((result: any) => {
        result.forEach((element) => {
          dataMerchant.push({
            name: element.BussinesName,
            value: element.MerchantCode,
          });
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
        }
      });

    return [
      {
        name: "Código de pago",
        key: "paymentCode",
        type: "text",
      },
      {
        name: "Estado",
        key: "status",
        type: "select",
        data: dataStatus,
      },
      {
        name: "Referencia Comercial",
        key: "merchantSalesID",
        type: "text",
      },
      {
        name: "N° Operación",
        key: "PublicId",
        type: "text",
      },
      {
        name: "Periodo",
        key: ["from_date", "to_date"],
        type: "period",
      },
      {
        name: "Comercio",
        key: "mcode",
        type: "select",
        data: dataMerchant,
      },
      //New Filter
      {
        name: "Documento",
        key: "cdoc",
        type: "text",
        data: dataProvider,
      },
      {
        name: "Proveedores",
        key: "providerCode",
        type: "select",
        data: dataProvider,
      },
      {
        name: "Servicio",
        key: "serviceCode",
        type: "select",
        data: dataMerchant,
      },
      //
      {
        name: "Descargar",
        key: "download",
        type: "button",
      },
    ];
  }

  changeDataTable(data) {
    if (data.type && data.type === "download") {
      this.downloadReport(data);
    } else {
      this.dataFilters = {
        ...this.dataFilters,
        ...data
      }
      this.allOperationsCashInData = this.allOperationsCashIn(this.dataFilters);
    }
  }

  downloadReport(dat) {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "listAllOperationCashin/download",
      data: dat.data,
    };
    this.api.api(data);
  }
}
