import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-sttlement-detail',
  templateUrl: './sttlement-detail.component.html',
  styleUrls: ['./sttlement-detail.component.css']
})
export class SttlementDetailComponent implements OnInit {

  public details: any = this.processDetail();
  public historical: any = this.processHistorical();
  public activity: any = this.processActivity();
  public titlePage: any;

  constructor(private aR: ActivatedRoute, private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async processDetail() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];
    this.titlePage = 'Operation > Cash In > Liquidaciones > ' + id;

    return {
      headers: this.getHeadersDetails(),
      data: this.getDetails(id, 'settlement/SettelmentDetail')
    };
  }


  async getDetails(id, service) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }


  getHeadersDetails() {
  return [
    {
      name: 'Id',
      key: 'Settlement-PublicId'
    },
    {
      name: 'Total Record',
      key: 'Settlement-TotalRecord'
    },
    {
      name: 'Total Amount',
      key: 'Settlement-TotalAmount'
    },
    {
      name: 'Sub Total',
      key: 'Settlement-SubTotal'
    },
    {
      name: 'Total Fee',
      key: 'Settlement-TotalFee'
    },
    {
      name: 'Total Tax',
      key: 'Settlement-TotalTax'
    },
    {
      name: 'Total Other',
      key: 'Settlement-TotalOther'
    },
    {
      name: 'Currency',
      key: 'Settlement-CurrencyCode'
    }
  ];
}


  // HISTORICAL

  getHeadersHistorical() {

    return [
      {
        name: 'Fecha de Creacion',
        key: 'created_at'
      },
      {
        name: 'Evento',
        key: 'Status'
      }
    ];
  }

  async processHistorical() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];

    return await this.getDetails(id, 'settlement/SettelmentDetail').then((data: any) => {

      const activity = [];
      data.Activity.forEach(e => {
        // e.Status = this.api.getStatusByID(e.Status.toString());
        activity.push(e);
      });

      return {
          headers: this.getHeadersHistorical(),
          data: activity,
          title: 'Historico:'
      };
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }


  // Activity

  getHeadersActivity() {

    return [
      {
        name: 'Public Id',
        key: 'PublicId'
      },
      {
        name: 'MerchantSales Id',
        key: 'MerchantSalesID'
      },
      {
        name: 'Currency',
        key: 'CurrencyCode'
      },
      {
        name: 'Amount',
        key: 'Amount'
      },
      {
        name: 'SubTotal',
        key: 'SubTotal'
      },
      {
        name: 'Fee',
        key: 'Fee'
      },
      {
        name: 'Tax',
        key: 'Tax'
      },
      {
        name: 'OtherFee',
        key: 'OtherFee'
      }
    ];
  }

  async processActivity() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];

    return await this.getDetails(id, 'settlement/SettelmentDetail').then((data: any) => {

      return {
        headers: this.getHeadersActivity(),
        data: data.RecordsTransactions,
        title: 'Transacciones:'
      };

    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

}
