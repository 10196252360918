import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arqueo-cajas',
  templateUrl: './arqueo-cajas.component.html',
  styleUrls: ['./arqueo-cajas.component.scss']
})
export class ArqueoCajasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
