import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-operations-cashout',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsCashoutComponent implements OnInit {

  public allOperationsCashOutData: any = this.allOperationsCashOut();
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Cash Out';

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {

  }

  async allOperationsCashOut(dataInfo: any = false) {
    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'cashout/operations',
      data: []
    }

    let headers = [
      {
        'name': 'Operación',
        'key': 'PublicID',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'Customer-Info-FullName',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'Customer-InfoBank-CustomerBank',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Fecha de depósito',
        'key': 'PaymementDate',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      }
    ]

    if (dataInfo !== false) {
      data.service = 'listAllOperationCashOutWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'operation-cashout'
    }

  }



  async getFilters() {

    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    }

    let dataStatus = this.api.getStatus();


    let dataMerchant = [];
    let dataBanks = [];

    await this.api.api({
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'listBanksCashout'
    }).toPromise().then((result: any) => {

      result.forEach(element => {
        dataBanks.push({
          name: element.ShortName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if (error.status == 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });


    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status == 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Cliente',
        'key': 'FullName',
        'type': 'text'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'Banco',
        'key': 'bankCode',
        'type': 'select',
        'data': dataBanks
      },
      {
        'name': 'N° Operación',
        'key': 'publicId',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Descargar',
        'key': 'download',
        'type': 'button'
      }

    ];

  }

  changeDataTable(data) {


    if (data.type && data.type == 'download') {
      this.downloadReport(data);
    } else {
      this.allOperationsCashOutData = this.allOperationsCashOut(data);
    }


  }

  downloadReport(dat) {
    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'listAllOperationCashOut/download',
      data: dat.data
    }
    this.api.api(data);
  }



}
