import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
} from 'ng-apexcharts';

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  colors: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public filters: any = this.getFilters();

  public path = '';
  user: any;
  titlePage: any = 'Recargas';
  LastStatus = new FormControl('14');
  status = this.api.getStatus();
  typeCashInValue = '1';
  typeCashInName = 'Transacciones';
  single: any[];
  view: any[] = [500, 226];
  dataChart1: any = {};
  dataChart2: any[];
  dataMerchant: any;

  // from_date: any = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD');
  // to_date: any = moment().add(1, 'days').format('YYYY-MM-DD');
  from_date: any = moment().subtract(6, 'days').format('YYYY-MM-DD');
  to_date: any = moment().add(1, 'days').format('YYYY-MM-DD');

  gradient = true;
  showLegend = true;
  showLabels = true;
  isDoughnut = false;
  selectedCurrency = 'COP';
  selectMerchant = '';

  styleDate = {
    'min-width': '200px',
    'padding-right': '20px',
  };

  colorScheme = {
    domain: ['#00A3BE', '#00A245', '#ff9100', '#072146'],
  };

  constructor(private router: Router, private api: ApiService) {}

  async getFilters() {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants',
    };
    const dataMerchant = [];
    await this.api
      .api(data)
      .toPromise()
      .then((result: any) => {
        result.forEach((element) => {
          dataMerchant.push({
            name: element.BussinesName,
            value: element.MerchantCode,
          });
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });

    this.dataMerchant = dataMerchant;

    return [
      {
        name: 'Comercio',
        key: 'mcode',
        type: 'select',
        data: dataMerchant,
      },
      {
        name: 'Nuevo balance',
        key: 'new-balance',
        type: 'button',
      },
    ];
  }

  ngOnInit() {
    this.path = window.location.pathname.trim();
    this.user = JSON.parse(sessionStorage.getItem('ud'));
    this.getData();
    Object.assign(this, this.dataChart2);
  }

  toggleDataSeries(e) {
    return;
  }

  getData() {
    if (JSON.parse(sessionStorage.getItem('ud')) === null) {
      this.router.navigate(['/login']);
    }

    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'getstadistict_cashin',
      data: {
        from_date: moment(this.from_date).format('YYYY-MM-DD'),
        to_date: moment(this.to_date).format('YYYY-MM-DD'),
        currency_code: this.selectedCurrency,
        merchant_type: '6',
        merchant_code: this.selectMerchant,
      },
    };

    this.api
      .api(data)
      .toPromise()
      .then((result: any) => {
        this.dataChart1 = result.graphic1;
        this.dataChart2 = result.graphic2;
        this.getChart3();
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  getChart3() {
    const self = this;
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'getstadistict_line_cashin',
      data: {
        from_date: moment(this.from_date).format('YYYY-MM-DD'),
        to_date: moment(this.to_date).format('YYYY-MM-DD'),
        type: this.typeCashInValue,
        currency_code: this.selectedCurrency,
        merchant_code: this.selectMerchant,
        merchant_type: '6'
      },
    };

    this.api
      .api(data)
      .toPromise()
      .then((result: any) => {
        // result = {
        //   data: [],
        //   categories: [],
        // };
        console.log('result', result);
        this.chartOptions = {
          series: result.data,
          colors: result.colors,
          chart: {
            height: 400,
            width: 1000,
            type: 'line',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 3,
            curve: 'smooth',
            dashArray: [0],
          },
          legend: {
            tooltipHoverFormatter(val, opts) {
              return (
                val +
                ' - <strong>' +
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                '</strong>'
              );
            },
          },
          markers: {
            size: 5,
            hover: {
              sizeOffset: 2,
            },
          },
          xaxis: {
            labels: {
              trim: false,
            },
            categories: result.categories,
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter(val) {
                    return val;
                  },
                },
              },
              {
                title: {
                  formatter(val) {
                    return val;
                  },
                },
              },
              {
                title: {
                  formatter(val) {
                    return val;
                  },
                },
              },
            ],
          },
          grid: {
            borderColor: '#f1f1f1',
          },
        };
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  selectChangeHandler(event: any) {
    this.selectedCurrency = event.target.value;
    this.getData();
  }

  selectChangeMerchantHandler(event: any) {
    this.selectMerchant = event.target.value;
    this.getData();
  }

  selectChangeTypeHandler(event: any) {
    this.typeCashInValue = event.target.value;
    this.validateName(this.typeCashInValue);
    this.getChart3();
  }

  validateName(n: string) {
    switch (n) {
      case '1':
        this.typeCashInName = 'Transacciones';
        break;
      case '2':
        this.typeCashInName = 'Facturación';
        break;
      case '3':
        this.typeCashInName = 'Bruto';
        break;
      case '4':
        this.typeCashInName = 'Neto';
        break;
    }
  }
}
