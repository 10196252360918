import { HttpParams } from '@angular/common/http';
import { SessionService } from './../session.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from './../http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExtractService {

  baseEndpoint = 'extract';
  constructor(
    private http: HttpService,
    private session: SessionService
  ) { }

  ngOnInit(): void {
  }

  getRecords(Merchant = '', filters: any[]) {
    const params = this.setParams(filters);
    return this.http.get(this.buildEndpoint('extract',Merchant), null, params);
  }

  downloadRecords(Merchant = '', filters: any[]) {
    const params = this.setParams(filters);
    return this.http.download(this.buildEndpoint('extract/download', Merchant), null, params);
  }

  getTotals(Merchant = '', filters: any[]) {
    const params = this.setParams(filters);
    return this.http.get(this.buildEndpoint('extract/total', Merchant), null, params);
  }

  setParams(filters: any[]) {
    let params = new HttpParams();

    for (const key in filters) {
      if (filters[key] !== '') {
        params = params.append(key, filters[key]);
      }
    }
    return params;
  }

  private buildEndpoint(baseEndpoint, Merchant) {
    return Merchant ? `${baseEndpoint}/${Merchant}` : baseEndpoint;
  }
}
