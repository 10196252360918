import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { FileHandle } from 'src/app/directive/dragDrop.directive';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public dataInfoModal: any = this.getDataInfoModal();
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash Out > Nóminas';

  titleModal: any = '';
  isVisible: boolean = false;
  contentModal: any = '';
  operationNumber: any = '';
  listBanksPayroll: any;
  dataEventModal: any;
  okText: string = 'Generar';
  messageModal: any;
  dataMerchant: any;
  formModal: FormGroup;
  files: FileHandle[] = [];
  isUploadModalVisible = false;
  selectedPayRollId = '';
  modalHeader: any;

  constructor(private api: ApiService, private router: Router) {
    this.formModal = this.createFormGroup();
   }

  createFormGroup() {
    return new FormGroup({
      Comercio: new FormControl('1001'),
      BankCode: new FormControl('1002'),
      Reference: new FormControl(''),
      Amount: new FormControl(''),
      CurrencyCode: new FormControl('USD')
    });
  }

  ngOnInit() {
    this.dataInfoModal = this.getDataInfoModal();
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'payroll/list',
      data: []
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'PayrollID',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'Templete',
        'opc': true
      },
      {
        'name': 'Total Record',
        'key': 'TotalRecord',
        'opc': true
      },
      {
        'name': 'Total Amount',
        'key': 'TotalAmount',
        'opc': true
      },
      {
        'name': 'Currency',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Date',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'list',
        'opc': false,
        'functions': ['Download', 'Upload'],
      }
    ];

    if (dataInfo !== false) {
      data.service = 'payroll/listWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'payroll/list'
    };

  }

  onChangeModal(e) {
    console.log('aca');
    console.log(e);
    this.dataInfoModal = this.getDataInfoModal();
  }

  async getDataInfoModal() {

    const headers = [
      {
        'name': 'ID',
        'key': 'PublicId',
        'opc': true
      },
      {
        'name': 'Rode',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Bank',
        'key': 'Customer-InfoBank-CustomerBank',
        'opc': true
      },
      {
        'name': 'status',
        'key': 'LastStatus',
        'opc': true
      }
    ];

    return {
      headers: headers,
      data: this.getDetails(this.BankCode.value, 'payroll/listTransaction')
    };

  }

  async getDetails(id, service) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }

  async getFilters() {

    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'listBanksCashout'
    };

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.ShortName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    const dataStatus = this.api.getStatus();

    this.dataMerchant = dataMerchant;
    return [

      {
        'name': 'Bancos',
        'key': 'mcode',
        'type': 'select',
        'data':  this.dataMerchant
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'ID Liquidaciones',
        'key': 'publicid',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Nueva nomina',
        'key': 'new-payroll',
        'type': 'button',
      }

    ];

  }

  //EVENTS OUTPUT


  changeDataTable(data) {
    switch (data) {
      case 'new-payroll':
        this.getDataBankPayroll().then((result: any) => {
          this.listBanksPayroll = result;
          this.titleModal = 'Operaciones Pendientes';
          this.contentModal = 'generatePayroll';
          this.dataEventModal = 'generatePayroll';
        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        this.showModal();
        this.okText = 'Generar';
        break;
      case  'Upload':
        this.isUploadModalVisible = true;
        break;
      default:
        this.dataInfo = this.getDataInfo(data);
        break;
    }

  }

  functions(data) {
    this.isUploadModalVisible = true;
    this.selectedPayRollId = data['data'].PayrollID;
  }

  functionsModal(data) {
    this.dataInfoModal = this.getDataInfoModal();
  }


  async getDataBankPayroll() {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'payroll/listBanks'
    };
    return await this.api.api(data).toPromise();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case 'generatePayroll':
        this.newPayroll().then((result: any) => {
          this.isVisible = false;

          // TODO
          this.dataInfo = this.getDataInfo();

          // if (result.message === 'New balance updated successfull') {
          //   this.messageModal = 'Planilla Generada';
          // } else {
          //   this.messageModal = result.message;
          // }
          // this.dataEventModal = 'ok';
          // this.okText = 'Aceptar';
        }).catch(error => {
          console.log('ERROR', error);
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        break;
      case 'ok':
        this.isVisible = false;
        break;
      default:
        break;
    }


  }

  async newPayroll() {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'payroll/create',
      data: {
        BankCode: this.BankCode.value,
        metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails)
      }
    };
    return await this.api.api(data).toPromise();
  }

  async handleUploadRequest() {
    console.log(this.files[0].file);
    console.log(this.files[0]);
    console.log(this.files);
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'payroll/upload',
      data: {
        file: this.files,
        payrollid: this.selectedPayRollId,
        metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails)
      }
    };
    return await this.api.api(data).toPromise();
  }

  handleCancel(): void {
    this.formModal = this.createFormGroup();
    this.isVisible = false;
  }

  filesDropped(files: FileHandle[]): void {
    this.files = files;
  }

  upload(): void {
    this.handleUploadRequest().then((result: any) => {
      this.isUploadModalVisible = false;
      this.files = [];
    }).catch(error => {
      console.log('ERROR', error);
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  cancelUpload(): void {
    this.selectedPayRollId = '';
    this.files = [];
    this.isUploadModalVisible = false;
  }

  get Comercio() { return this.formModal.get('Comercio'); }
  get BankCode() { return this.formModal.get('BankCode'); }
  get Reference() { return this.formModal.get('Reference'); }
  get Amount() { return this.formModal.get('Amount'); }
  get CurrencyCode() { return this.formModal.get('CurrencyCode'); }

}
