import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sttlement',
  templateUrl: './sttlement.component.html',
  styleUrls: ['./sttlement.component.scss']
})
export class SttlementComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash In > Liquidaciones';

  titleModal: any = '';
  isVisible = false;
  contentModal: any = '';
  operationNumber: any = '';
  listBanksCashin: any;
  isAlertVisible = false;
  okText = 'Confirmar';
  selectedId = '';

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'settlement/list',
      data: []
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'SettlementID',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'TotalAmount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'Status',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'opc',
        'opc': false,
        'functions': ['Confirmar'],
      },
    ];

    if (dataInfo !== false) {
      data.service = 'settlement/listWithParams';
      data.data = dataInfo;
      
    }
    

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'activities-cashin-sttlement'
    };

  }



  async getFilters() {

    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    }
    let dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    let dataStatus = this.api.getStatus();

    return [

      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'ID Liquidaciones',
        'key': 'publicid',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },

    ];

  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    this.dataInfo = this.getDataInfo(data);
  }

  functions(e) {
    console.log(e.data);
    this.selectedId = e.data.SettlementID;
    switch (e.function) {
      case 'Confirmar':
        this.titleModal = 'Confirmar Liquidación';
        this.isVisible = true;
        this.okText = 'Confirmar';
        break;
      default:
      break;
    }
  }

  handleConfirm() {
    this.confirmRequest().then((result: any) => {
      this.handleAlertNotification();
      this.isVisible = false;
    }).catch(error => {
      console.log('ERROR', error);
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  async confirmRequest() {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'settlement/confirm',
      data: {
        SettlementId: this.selectedId,
        metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails)
      }
    };
    return await this.api.api(data).toPromise();
  }

  handleCancel() {
    this.isVisible = false;
  }

  handleAlertNotification(): void {
    this.isAlertVisible = true;
    this.dataInfo = this.getDataInfo();
    var that = this;
    setTimeout(function() {
      that.isAlertVisible = false;
    }, 3000);
  }

}
