import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NzModalRef, NzModalService } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../../../../../services/api.service";
import { AccessService } from "../../../../../services/access.services";
import { CountryService } from "../../../../../services/utils/country.service";

@Component({
  selector: 'app-partner-conciliation',
  templateUrl: './partner-conciliation.component.html',
  styleUrls: ['./partner-conciliation.component.scss']
})
export class PartnerConciliationComponent implements OnInit {
  public dataInfo: any = this.getDataInfo({Status: 1, MerchantType: 6});
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash In > Partner conciliation';

  isPIX: boolean = false;
  destroy$ = new Subject();
  titleModal:any = '';
  isVisibleExtornoModal: boolean = false;
  isVisibleCategorizarModal: boolean = false;
  isOkLoading: boolean = false;
  contentModal: any = '';
  userMeta: any;
  listBanksCashin: any;
  formForcePayment: FormGroup;
  formExtorno: FormGroup;
  messageModal: any = '';
  dataEventModal: any = '';
  dataForcePayment: any;
  okText = 'Pagar';
  isAlertVisible = false;
  isVisiblePagarModal = false;
  showQrGoogle = false;
  qrAuthGoogle = '';
  hasForcePaymentAndExtorno = false;
  confirmModal: NzModalRef;
  dataCategory: any;
  nomenclature: string;
  documentTypes: any[] = [];
  listCountries: any = [];
  currentRow: any;
  otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  publicIdControl = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  types: any[] = [
    {label: 'Total', value: 1},
    {label: 'Parcial', value: 2},
  ];
  extornoTypes: any[] = [
    {label: 'TRANSFER', value: 'TRANSFER'},
    {label: 'PIX', value: 'PIX'}
  ];
  documentTypesPER: any[] = [
    {label: 'DNI', value: 'DNI'},
    {label: 'RUC', value: 'RUC'},
    {label: 'PASAPORTE', value: 'PASAPORTE'},
    {label: 'CE', value: 'CE'},
  ];
  documentTypesBRA: any[] = [
    {label: 'CPF', value: 'CPF'},
    {label: 'CNPJ', value: 'CNPJ'},
  ];
  documentTypesCOL: any[] = [
    {label: 'CC', value: 'CC'},
    {label: 'CE', value: 'CE'},
    {label: 'NIT', value: 'NIT'},
    {label: 'CPF', value: 'CPF'},
    {label: 'CNPJ', value: 'CNPJ'},
  ];
  accountTypes: any[] = [
    {label: 'Ahorros', value: 'ahorros'},
    {label: 'Corriente', value: 'corriente'},
  ];
  keyTypes: any[] = [
    {label: 'Correo', value: 'correo'},
    {label: 'Teléfono', value: 'telefono'},
    {label: 'Alter key', value: 'alter key'},
    {label: 'CPF/CNP', value: 'CPF/CNP'},
  ];

  constructor(
      private api: ApiService,
      private router: Router,
      private modal: NzModalService,
      private fb: FormBuilder,
      private countryService: CountryService,
      private accessService: AccessService
  ) {
    this.formForcePayment = this.createFormGroup();
    this.formExtorno = this.createFormGroupExtorno();
    this.documentTypes = this.documentTypesCOL
  }

  ngOnInit() {
    this.changeTypeRefund();
    this.changeType()
    this.Amount.disable()
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createFormGroup() {
    return new FormGroup({
      bankCode: new FormControl('1001'),
      channel: new FormControl('1'),
      bankOperationNumber: new FormControl(''),
    });
  }

  createFormGroupExtorno() {
    return this.fb.group({
      BankData: this.fb.group({
        NameBank: ['', [Validators.required]],
        NumberAgence: ['', [Validators.required]],
        TypeAccount: [null, [Validators.required]],
        NumberAccount: ['', [Validators.required]],
        KeyValue: ['', [Validators.required]],
        KeyType: [null, [Validators.required]],
      }),
      OperationData: this.fb.group({
        TramaBankID: ['', [Validators.required]],
        Amount: ['', [Validators.required]],
        DocumentType: [null, [Validators.required]],
        DocumentNumber: ['', [Validators.required]],
        Email: ['', [Validators.required, Validators.email]],
        Phone: ['', [Validators.required]],
        FullName: ['', [Validators.required]],
        Type: [null, [Validators.required]],
        ExtornoType: [null, [Validators.required]],
      }),
    })
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'partnerConciliations',
      data: []
    };

    let headers = [
      {
        'name': 'PartnerConciliationID',
        'key': 'PartnerConciliationID',
        'opc': true
      },
      {
        'name': 'Fecha',
        'key': 'CreationTrama',
        'opc': true
      },
      {
        'name': 'Trama',
        'key': 'Trama',
        'opc': true
      },
      {
        'name': 'Descripción',
        'key': 'Detail',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'BankName',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'Status',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Pagar'],
      },
    ];


    if (dataInfo !== false) {
      data.service = 'partnerConciliations/listWithParams';
      data.data = dataInfo;
    }
    const list: any = await this.api.api(data).toPromise()

    return {
      headers: headers,
      data: await list.data,
    }

  }

  updateStatus(info, status, response) {
    const data = {
      service: 'partnerConciliations-put',
      id: info.data.PartnerConciliationID,
      data: {
        Status: status
      }
    };

    this.api.api(data).toPromise().then(() => {
      this.dataInfo = this.getDataInfo({Status: 1, MerchantType: 6})
      this.success(response.Message ? response.Message : response.message)
      this.dataCategory = null
    }).catch(e => {
      this.error(e.message || e.error.message)
    })
  }

   async getFilters() {
    const data = {
      service: 'bank',
      data: []
    };

    let banks = [];
    await this.api.api(data).toPromise().then((result: any) => {
      result.forEach(element => {
        banks.push({
          name: element.LegalName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    this.listCountries = await this.countryService.getDataCountry()
    return [

      {
        'name': 'Trama',
        'key': 'TramaBank',
        'type': 'text'
      },
      {
        'name': 'Bancos',
        'key': 'BankCode',
        'type': 'select',
        'data': banks
      },
      {
        'name': 'País',
        'key': 'country',
        'type': 'select',
        'data': this.listCountries
      },
      {
        'name': 'Estado',
        'key': 'Status',
        'type': 'select',
        data: [
          {
            name: 'Pendiente',
            value: 1,
          },
          {
            name: 'Pagado',
            value: 2,
          },
          {
            name: 'Extornado',
            value: 3,
          }
        ]
      },
      {
        'name': 'Descargar',
        'key': 'download',
        'type': 'button'
      },



    ];

  }

  changeDataTable(data) {
    if (data.type === 'download') {
      this.api.queryDownload('listAllTramaBanks/download', data.data)
      return
    }
    data['MerchantType'] = 6
    this.dataInfo = this.getDataInfo(data);
  }

  showConfirm(info, title?: string, message?:string): void {
    const data = this.getSendDataForcePayment(info);

    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent: message,
      nzOnOk: () => {
        //this.loaderService.updateLoading(true);
        this.api.api(data).toPromise().then((resp: any) => {
          this.updateStatus(info, 2, resp)
        }).catch(e => {
          //this.loaderService.updateLoading(false);
        })
      }

    });
  }

  sendForcePayment(info) {
    //this.loaderService.updateLoading(true);
    const data = this.getSendDataForcePayment(info);
    return this.api.api(data).toPromise().then((resp: any) => {
      return resp
    }).catch(e => {
      this.error(e.message || e.error.message)
    })
  }

  getSendDataForcePayment(info){
    return {
      service: 'forcePayment',
      data: {
        publicId: info.data.selectedTransaction.PublicId,
        bankCode: info.data.BankCode,
        channel: "1",
        bankOperationNumber: info.data.OperationNumber,
        metadata: JSON.stringify({user: info.userDetails.fullname}),
      }
    }
  }

  async functions(f) {
    let title = ''
    let message = ''
    this.userMeta = {user: f.userDetails.fullname}
    this.dataCategory = f
    this.hasForcePaymentAndExtorno = false
    console.log('ffff---->', f)
    window.scrollTo(0, 0);
    switch (f.function) {
      case 'Extornar':
        this.openExtorno(f)
        break;

      case 'Categorizar':
        this.nomenclature = f.data.Trama
        this.isVisibleCategorizarModal = true;
        break;

      case 'Pagar':
        try {
          const trama:any = await this.api.api({
            service: 'getTrama',
            tramaID: f.data.TramaBankID
          }).toPromise()
          if(trama) {
            const metadata = JSON.parse(trama.Metadata)
            console.log('trama', trama)
            if(!trama.OperationDetail) {
              alert('El OperationDetail es obligatorio')
              throw "OperationDetail is null";
          }
            const data = {
              service: "v2/payment_order",
              data: {
                'commerceID' : trama.OperationDetail,
                'merchantSalesID' : f.data.OperationNumber,
                'amount' : f.data.Amount,
                'consignmentDate': trama.OperationDate.replace(/\//g, "-"),
                'paymentMethod': 'BancolombiaConvenio',
                'accountNumberFrom' : metadata.document,
                'accountNumberTo': trama.robot_process.robot.NumberBankAccount
              }
            }

            const response:any = await this.api.api(data).toPromise()
            if(response.status) {
              const data = {
                service: "updatePartnerConciliations",
                partnerID: f.data.PartnerConciliationID,
                data: {
                  "Status": 2
                }
              }
              const updateStatus:any = await this.api.api(data).toPromise()
              if(updateStatus.status)
                this.getDataInfo({
                  Status: 1,
                  MerchantType: 6
                })
              else {
                alert('Notificacion exitosa, pero no se pudo actualizar el estado del partner conciliation')
              }
            }
            else {
              alert('Error en Notificacion')
              console.error(response)
              throw `Error ${response.message ? response.message : 'en notificacion'}`;
            }

          }
          console.log('trama', trama)
        } catch (error) {
          console.log('error', error)
        }
        break;
      case 'PagarSubItem':
        const AmountTrama = Number(f.data.Amount)
        const AmountTransaction = Number(f.data.selectedTransaction.Amount)
        const surplus = Number((Math.round((AmountTrama - AmountTransaction) * 100) / 100).toFixed(2))

          if (surplus > 0) {
            this.modal.confirm({
              nzTitle: 'Pagar',
              nzContent: `La trama tiene un sobrante de $${surplus}, si continua se tendrá que procesar un extorno por el total del sobrante. ¿Desea continuar?`,
              nzOkText: 'Continuar',
              nzOnOk: () => {
                this.hasForcePaymentAndExtorno = true
                this.dataForcePayment = f
                this.openExtorno(f, surplus)
              }
            });
          } else if(surplus === 0){
            title = 'Pagar'
            message = 'Desea continuar con la operación?'
            this.showConfirm(f, title, message)
          } else {
            title = 'Pagar'
            message = `La trama  tiene un faltante de $${Math.abs(surplus)}, ¿Desea continuar?`
            this.showConfirm(f, title, message)
          }
        break;

      default:
        break;
    }
  }

  handleCancel() {
    this.isVisibleExtornoModal = false
    this.isVisibleCategorizarModal = false
    this.dataCategory = null
    this.formExtorno.reset()
  }

  openExtorno(f, surplus?: number) {
    const dataPayment = f.data.ListTransactions.length ? f.data.ListTransactions[0].DataPayment : null
    this.isVisibleExtornoModal = true
    this.Amount.setValue(f.data.Amount)
    this.DocumentNumber.setValue(dataPayment ? dataPayment.DocNumber : '')
    this.Phone.setValue(dataPayment ? dataPayment.Mobile : '')
    this.Email.setValue(dataPayment ? dataPayment.Email : '')
    this.FullName.setValue(
        dataPayment
            ? dataPayment.FirstName + ' ' + dataPayment.LastName
            : '')
    this.DocumentType.setValue(dataPayment ? dataPayment.DocType : '')
    this.TramaBankID.setValue(f.data.TramaBankID)
    if (surplus) {
      this.Type.setValue(1)
      this.Type.disable()
      this.Amount.setValue(surplus)
    }
    this.Amount.setValidators([Validators.required, Validators.max(surplus ? surplus : Number(f.data.Amount))])
    this.Amount.updateValueAndValidity()
  }

  extornar(NoAccount = false) {
    if (NoAccount && this.OperationData.invalid){
      this.OperationData.markAllAsTouched();
      return;
    } else if (!NoAccount && this.formExtorno.invalid) {
      this.formExtorno.markAllAsTouched();
      return;
    }

    const data = {
      service: 'extornos',
      data: {
        ExtornoType: NoAccount ? this.extornoTypes[0].value : this.ExtornoType.value,
        Type: this.Type.value,
        TramaBankID: this.TramaBankID.value,
        Amount: Number(this.Amount.value),
        Details:{
          DocumentType: this.DocumentType.value,
          DocumentNumber: this.DocumentNumber.value,
          Email: this.Email.value,
          Phone: this.Phone.value,
          NameBank: this.NameBank.value,
          NumberAgence: this.NumberAgence.value,
          TypeAccount: this.TypeAccount.value,
          NumberAccount: this.NumberAccount.value,
          FullName: this.FullName.value
        },
        NoAccount: NoAccount,
        MetaData: {
          KeyValue: this.KeyValue.value,
          KeyType: this.KeyType.value
        }
      }
    }

    if (this.hasForcePaymentAndExtorno) {
      this.sendExtorno(data).then(() => {
        this.sendForcePayment(this.dataForcePayment).then((resp) => {
          this.updateStatus(this.dataCategory, 2, resp)
          this.dataForcePayment = null
        })
      })
    } else {
      this.sendExtorno(data).then((resp) => {
        this.updateStatus(this.dataCategory, 3, resp)
        this.dataCategory = null
      })
    }
  }

  sendExtorno(data){
    return this.api.api(data).toPromise().then((resp: any) => {
      this.hasForcePaymentAndExtorno = false;
      this.isVisibleExtornoModal = false
      return resp
    }).catch(err => {
      this.isVisibleExtornoModal = false
      this.hasForcePaymentAndExtorno = false;
      this.error(err.error.message || err.message)
    })
  }

  categorizar(category) {
    const data = {
      service: 'nomenclatures',
      id: this.dataCategory.data.SecureKey,
      data: {
        Value: this.nomenclature ? this.nomenclature : this.dataCategory.data.Trama,
        Type: category
      }
    }

    if (category){
      this.api.api(data).toPromise().then((resp: any) => {
        this.dataInfo = this.getDataInfo({Status: 1, MerchantType: 6})
        this.isVisibleCategorizarModal = false
        this.success(resp.message)
        this.dataCategory = null
        this.nomenclature = ''
      })
    }
  }

  handleConfirmPagarModal() {
    // this.isVisiblePagarModal = false;
    this.validateToken(this.otpControl.value);
    
    // this.manualPayment()
  }

  manualPayment() {
    const data = {
      publicId: this.publicIdControl.value,
      partnerConciliationId: this.currentRow.PartnerConciliationID,
      metadata: JSON.stringify({user: this.userMeta.user, description: this.description.value}),
    }
    /*this.api.queryPost('partnerConciliations/payment', data).subscribe((result: any) => {
      this.success(result.message)
      this.dataInfo = this.getDataInfo({Status: 1})
      this.publicIdControl.setValue('');
    }, error => {
      this.publicIdControl.setValue('');
    })*/
  }

  success(title, message?: string): void {
    this.modal.success({nzTitle: title, nzContent: message});
  }

  error(error): void {
    this.modal.error({nzTitle: 'Mensaje de error', nzContent: error});
  }

  changeType() {
    this.Type.valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe(value => {
      if (value === 1) {
        this.Amount.setValue(this.dataCategory.data.Amount)
        this.Amount.disable()
      } else {
        this.Amount.setValue('')
        this.Amount.enable()
      }
    })
  }

  changeTypeRefund(){
    this.ExtornoType.valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe(res => {
      this.isPIX = res === 'PIX';
      if (res === 'PIX') {
        this.clearValuesBankData(['NameBank', 'NumberAgence', 'TypeAccount', 'NumberAccount'])
        this.clearValidationsBankData(['NameBank', 'NumberAgence', 'TypeAccount', 'NumberAccount'])

        this.KeyType.setValidators([Validators.required])
        this.KeyType.updateValueAndValidity()
        this.KeyValue.setValidators([Validators.required])
        this.KeyValue.updateValueAndValidity()
      } else if(res === 'TRANSFER') {
        const listTransactions = this.dataCategory.data.ListTransactions
        const metaData = listTransactions.length ? listTransactions[0].DataPayment.MetaData ? listTransactions[0].DataPayment.MetaData : null : null
        this.clearValuesBankData(['KeyType', 'KeyValue'])
        this.clearValidationsBankData(['KeyType', 'KeyValue'])
        if (this.dataCategory.data.ListTransactions.length > 0) {
          this.NumberAccount.setValue(metaData ? metaData.NumberAccount : '')
          this.NumberAgence.setValue(metaData ? metaData.NumberAgence : '')
        }
        this.NameBank.setValidators([Validators.required])
        this.NameBank.updateValueAndValidity()
        this.NumberAgence.setValidators([Validators.required])
        this.NumberAgence.updateValueAndValidity()
        this.TypeAccount.setValidators([Validators.required])
        this.TypeAccount.updateValueAndValidity()
        this.NumberAccount.setValidators([Validators.required])
        this.NumberAccount.updateValueAndValidity()
      }
    })
  }

  handleCancelPagarModal() {
    this.otpControl.setValue('');
    this.isVisiblePagarModal = false;
  }

  clearValuesBankData(keys) {
    keys.forEach(key => {
      this.BankData.get(key).setValue('')
    })
  }

  clearValidationsBankData(keys) {
    keys.forEach(key => {
      this.BankData.get(key).clearValidators()
      this.BankData.get(key).updateValueAndValidity()
    })
  }

  async getDataBankCashin(){
    let data = {
      service: 'listBanksCashin'
    }
    return await this.api.api(data).toPromise();
  }

  resetQr(){
    this.showQrGoogle = true;
    /*this.accessService.getQrGoogle(true).subscribe(resulQr => {
      this.qrAuthGoogle = resulQr.data;
    });*/
  }

  validateToken(token: string | null): void {
		if (token) {
			/*this.accessService.validateAuthToken(token).subscribe(
				(res) => {
          this.isVisiblePagarModal = false;
          this.manualPayment()
				},
				(error) => {
          this.error('El código ingresado es incorrecto');
          this.isVisiblePagarModal = false;
					this.otpControl.setValue('');
				}
			);*/
		}
	}

  get isTypeValid() {return this.Type.invalid && this.Type.touched}
  get isAmountValid() {return this.Amount.invalid && this.Amount.touched}
  get isExtornoTypeValid() {return this.ExtornoType.invalid && this.ExtornoType.touched}
  get isDocumentTypeValid() {return this.DocumentType.invalid && this.DocumentType.touched}
  get isDocumentNumberValid() {return this.DocumentNumber.invalid && this.DocumentNumber.touched}
  get isFullNameValid() {return this.FullName.invalid && this.FullName.touched}
  get isPhoneValid() {return this.Phone.invalid && this.Phone.touched}
  get isEmailValid() {return this.Email.invalid && this.Email.touched}
  get isNameBankValid() {return this.NameBank.invalid && this.NameBank.touched}
  get isNumberAgenceValid() {return this.NumberAgence.invalid && this.NumberAgence.touched}
  get isTypeAccountValid() {return this.TypeAccount.invalid && this.TypeAccount.touched}
  get isNumberAccountValid() {return this.NumberAccount.invalid && this.NumberAccount.touched}
  get isKeyValueValid() {return this.KeyValue.invalid && this.KeyValue.touched}
  get isKeyTypeValid() {return this.KeyType.invalid && this.KeyType.touched}

  get OperationData() {return this.formExtorno.get('OperationData')}

  get TramaBankID() {return this.formExtorno.get('OperationData').get('TramaBankID')}
  get Type() {return this.formExtorno.get('OperationData').get('Type')}
  get Amount() {return this.formExtorno.get('OperationData').get('Amount')}
  get ExtornoType() {return this.formExtorno.get('OperationData').get('ExtornoType')}
  get DocumentType() {return this.formExtorno.get('OperationData').get('DocumentType')}
  get DocumentNumber() {return this.formExtorno.get('OperationData').get('DocumentNumber')}
  get FullName() {return this.formExtorno.get('OperationData').get('FullName')}
  get Phone() {return this.formExtorno.get('OperationData').get('Phone')}
  get Email() {return this.formExtorno.get('OperationData').get('Email')}

  get BankData() {return this.formExtorno.get('BankData')}

  get NameBank() {return this.formExtorno.get('BankData').get('NameBank')}
  get NumberAgence() {return this.formExtorno.get('BankData').get('NumberAgence')}
  get TypeAccount() {return this.formExtorno.get('BankData').get('TypeAccount')}
  get NumberAccount() {return this.formExtorno.get('BankData').get('NumberAccount')}
  get KeyValue() {return this.formExtorno.get('BankData').get('KeyValue')}
  get KeyType() {return this.formExtorno.get('BankData').get('KeyType')}

}
