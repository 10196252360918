import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";

interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: "app-model1",
  templateUrl: "./model1.component.html",
  styleUrls: ["./model1.component.scss"],
})
export class Model1Component implements OnInit, OnChanges {
  @Input() data: any;
  @Output() dataOutput = new EventEmitter<any>();

  infoTable: any;
  headers: any;
  source: any;
  title: any = false;
  isVisible: boolean = false;
  titleModal: any;
  modalInfo: any;
  modalKeys: any = [];
  contentModal: any = "";
  classFunctions: any = "";
  sortName = "";
  sortValue = "";
  toggleSortValue = true;
  loading = false;
  isOptionList = false;
  from_date: null;
  to_date: null;

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data) => {
      this.from_date = data.from_date ? data.from_date : null;
      this.to_date = data.to_date ? data.to_date : null;
    });
    this.getInfo();
  }

  getInfo() {
    this.loading = true;
    this.data
      .then((data: any) => {
        if (data.title) {
          this.title = data.title;
        }

        this.headers = data.headers;

        if (this.source === "activities-cashin-forcepayment") {
          this.source = "operation-cashin";
          this.classFunctions = "buttonPay";
        } else if (this.source === "activities-cashin-sttlement") {
          // this.source = 'operation-cashin';
          this.classFunctions = "buttonPay";
        } else {
          this.source = data.source;
        }
        this.infoTable = data.data;
        console.log(this.infoTable);
        this.loading = false;
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
          this.loading = false;
        }
      });
  }

  ngOnChanges() {
    this.getInfo();
  }

  getDataKeys(e, h) {
    return e[h.key.split("-")[0]][h.key.split("-")[1]][h.key.split("-")[2]];
  }

  getColor(e) {
    let color = "#000000";
    if (e === 0 || e === 11 || e === 18) {
      color = "#b20000";
    }
    if (e === 12 || e === 14) {
      color = "#00ce2b";
    }
    if (e === 10) {
      color = "#E59D20";
    }
    if (e === 13 || e === 16 || e === 15) color = "#ff7e00";

    return color;
  }

  // INFORMATION OF REQUEST AND RESPONSE
  viewModal(e) {
    this.titleModal = "Informaci�n";
    this.modalInfo = JSON.parse(JSON.parse(e));
    this.contentModal = "";

    Object.keys(this.modalInfo).forEach((e) => {
      switch (e) {
        case "message":
          this.contentModal += `
                              <div class="d-flex">
                                <div class="col-6 font-weight-bold">Mensaje</div>
                                <div><b>:</b> ${this.modalInfo[e]}</div>
                              </div>
                              `;
          break;

        default:
          this.contentModal += `
                              <div class="d-flex">
                                <div class="col-6 font-weight-bold">${e}</div>
                                <div><b>:</b> ${this.modalInfo[e]}</div>
                              </div>
                              `;

          break;
      }
    });

    this.showModal();
  }

  // MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  // UTILITIES

  getNameStatus(e) {
    if (e === 0) {
      e = "o";
    }
    return this.api.getStatusByID(e.toString());
  }

  getNameStatusCashOut(e) {
    if (e === 0) {
      e = "o";
    }
    return this.api.getStatusByIDCashOut(e.toString());
  }

  // sort(sort: any): void {
  //   this.sortName = sort.key;
  //   this.sortValue = sort.value;
  //   console.log(sort);
  //   console.log(this.infoTable);

  //   // this.search();
  // }

  sort(sort: { key: string; value: string }): void {
    //this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log(sort);
    this.searchData(sort.key);
  }

  test(key: any) {
    console.log(key);
    this.toggleSortValue = !this.toggleSortValue;
    console.log(this.infoTable);
    this.infoTable.sort(function(a, b) {
      const x = b.PublicId.toLowerCase();
      const y = a.PublicId.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    console.log(this.infoTable);
  }

  searchData(value: string): void {
    // this.toggleSortValue = this.toggleSortValue;
    // this.infoTable.sort(function(c: any, d: any)  { return c.Amount - d.Amount; });
    // this.infoTable.sort(function(c, d){
    //   var x = c.PublicId.toLowerCase();
    //   var y = d.PublicId.toLowerCase();
    //   if (x < y) {return -1;}
    //   if (x > y) {return 1;}
    //   return 0;
    // });
  }

  // search(): void {
  //   /** filter data **/
  //    const filterFunc = (item: { name: string; age: number; address: string }) =>
  //      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
  //   //   (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
  //   // const data = this.listOfData.filter(item => filterFunc(item));
  //   /** sort data **/
  //   if (this.sortName && this.sortValue) {
  //     this.listOfDisplayData = data.sort((a, b) =>
  //       this.sortValue === 'ascend'
  //         ? a[this.sortName!] > b[this.sortName!]
  //           ? 1
  //           : -1
  //         : b[this.sortName!] > a[this.sortName!]
  //         ? 1
  //         : -1
  //     );
  //   } else {
  //     this.listOfDisplayData = data;
  //   }
  // }

  //  FUNCTIONS EVENTS

  functionsEvent(f, data, event = false) {
    const info = {
      function: f,
      data: data,
      userDetails: JSON.parse(sessionStorage.getItem("ud")).userDetails,
      event: event,
    };
    this.dataOutput.emit(info);
  }

  showOptionList() {
    this.isOptionList = !this.isOptionList;
  }
}
