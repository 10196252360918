import { SessionService } from "./session.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";

const API = environment.api;

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient, private session: SessionService) { }

  public get(endPoint: string, dataHeader?: HttpHeaders, params?: HttpParams) {
    const bearerToken = this.session.getToken();
    const headers = dataHeader
      ? dataHeader
      : new HttpHeaders()
        .set("Authorization", "Bearer " + bearerToken)
        .set("Content-Type", "application/json");

    return this.http.get(`${API}/${endPoint}`, { params, headers });
  }

  public download(
    endPoint: string,
    dataHeader?: HttpHeaders,
    params?: HttpParams
  ) {
    const bearerToken = this.session.getToken();
    const headers = dataHeader
      ? dataHeader
      : new HttpHeaders()
        .set("Authorization", "Bearer " + bearerToken)
        .set("Content-Type", "application/json");
    return this.http.get(`${API}/${endPoint}`, {
      responseType: "blob",
      headers,
      params,
    });
  }

  public post(endPoint: string, data?: any, dataHeader?: HttpHeaders) {
    const bearerToken = this.session.getToken();
    const headers = dataHeader
      ? dataHeader
      : new HttpHeaders()
        .set("Authorization", "Bearer " + bearerToken)
        .set("Content-Type", "application/json");
    data["metadata"] = this.session.getUserDetails();
    const endpointWithoutFormat = `${API}/${endPoint}`;
    const endpoint = endpointWithoutFormat
      .replace(/\/\//g, () => "/")
      .replace("https:/", () => "https://");
    return this.http.post(endpoint, data, { headers });
  }
}
