import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  urlCore = environment.api;
  urlMultiservi = environment.urlMultiservi
  public params: any;
  constructor(private http: HttpClient, public router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf("auth") === -1) {
      let idToken = "";
      if (sessionStorage.getItem("ud") && sessionStorage.getItem("ud") !== "") {
        idToken = JSON.parse(sessionStorage.getItem("ud")).token;
      }

      if (idToken) {
        const cloned = req.clone({
          headers: req.headers.set("Authorization", "Bearer " + idToken),
        });

        return next.handle(cloned);
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }

  api(datos) {
    const header = {
      headers: new HttpHeaders()
        .set("Authorization", `Bearer ${datos.token}`)
        .set("Content-Type", "application/json"),
    };

    const headerDownload = {
      headers: new HttpHeaders()
        .set("Authorization", `Bearer ${datos.token}`)
        .set("Content-Type", "application/json")
        .set("responseType", "blob"),
    };

    const headerUpload = {
      headers: new HttpHeaders()
        .set("Authorization", `Bearer ${datos.token}`)
        .set("Content-Type", null)
        .set("Accept", "multipart/form-data"),
    };
    console.log("datos.filters", datos);
    switch (datos.service) {
      case "cashin/operations":
        return this.http.get(
          `${this.urlCore + "cashin/operations"}${this.param(
            datos.data.filters
          )}`,
          header
        );
      case "cashout/operations":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "auth/login":
        return this.http.post(`${this.urlCore + datos.service}`, datos);
      case "auth/logout":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "merchants":
        return this.http.get(
          `${this.urlCore + "merchant?MerchantType=6"}`,
          header
        );

      case "provider":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "provider/balance":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "provider/balance/update":
        return this.http.put(
          `${this.urlCore + "provider/balance"}`,
          datos.data,
          header
        );
      case "provider/listBalanceWithParams":
        return this.http.get(
          `${this.urlCore + "provider/balance" + this.param(datos.data)}`,
          header
        );

      case `listProviderServices`:
        return this.http.get(
          `${this.urlCore + datos.service + this.param(datos.data)}`,
          header
        );

      case "provider/detail":
        return this.http.get(
          `${this.urlCore + datos.service + "/" + datos.data}`,
          header
        );

      case "recharge/balance/Conciliation":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "recharge/balance/Conciliation/listWithParams":
        return this.http.get(
          `${
            this.urlCore +
            "recharge/balance/Conciliation" +
            this.param(datos.data)
          }`,
          header
        );

      case "countries":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "merchant":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "listAllOperationCashinWithParams":
        return this.http.get(
          `${this.urlCore + "cashin/operations" + this.param(datos.data)}`,
          header
        );
      case "listAllOperationCashOutWithParams":
        return this.http.get(
          `${this.urlCore + "cashout/operations" + this.param(datos.data)}`,
          header
        );
      case "listBanksCashout":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "cashin/operations/detail":
        return this.http.get(
          `${this.urlCore + datos.service + "/" + datos.data}`,
          header
        );
      case "cashout/operations/detail":
        return this.http.get(
          `${this.urlCore + datos.service + "/" + datos.data}`,
          header
        );
      case "forcePayment/listOperation":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "forcePayment/listOperationWithParams":
        return this.http.get(
          `${
            this.urlCore + "forcePayment/listOperation" + this.param(datos.data)
          }`,
          header
        );
      case "listBanksCashin":
        return this.http.get(
          `${this.urlCore + datos.service + "?country=COL"}`,
          header
        );
      case "forcePayment":
        return this.http.post(
          `${this.urlCore + datos.service}`,
          datos.data,
          header
        );
      case "notification/listOperation":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "notification/listOperationWithParams":
        return this.http.get(
          `${
            this.urlCore + "notification/listOperation" + this.param(datos.data)
          }`,
          header
        );
      case "settlement/SettelmentDetail":
        return this.http.get(
          `${this.urlCore + datos.service + "/" + datos.data}`,
          header
        );
      case "settlement/list":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "settlement/listWithParams":
        return this.http.get(
          `${this.urlCore + "settlement/list" + this.param(datos.data)}`,
          header
        );
      case "settlement/confirm":
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case "balance/listBalance":
        return this.http.get(
          `${this.urlCore + datos.service + "?MerchantType=6"}`,
          header
        );
      case "balance/listBalanceWithParams":
        return this.http.get(
          `${this.urlCore + "balance/listBalance" + this.param(datos.data)}`,
          header
        );
      case "notification/execute/":
        return this.http.get(
          `${this.urlCore + datos.service + datos.data}`,
          header
        );
      case "balance/create":
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case "balance/detail":
        return this.http.get(
          `${
            this.urlCore +
            datos.service +
            "/" +
            datos.data.id +
            this.param(datos.data.filters)
          }`,
          header
        );
      /* */
      // case 'balance/detailParams':
      //   return this.http.get(`${this.urlCore + 'balance/detail'+ this.param(datos.data)}`, header);
      /* */
      case "payroll/list":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "payroll/listWithParams":
        return this.http.get(
          `${this.urlCore + "payroll/list" + this.param(datos.data)}`,
          header
        );
      case "payroll/listBanks":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "user/admin":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "user/merchant":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "profile/admin":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "profile/merchant":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "payroll/detail":
        return this.http.get(
          `${this.urlCore + datos.service + "/" + datos.data}`,
          header
        );
      case "payroll/listTransaction":
        return this.http.get(
          `${this.urlCore + datos.service + "/" + datos.data}`,
          header
        );
      case "payroll/create":
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case "payroll/upload":
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case "conciliation/info":
        return this.http.get(
          `${this.urlCore + datos.service + this.param(datos.data)}`,
          header
        );
      case "listAllOperationCashin/download":
        window.location.href =
          this.urlCore +
          "listAllOperationCashin/download" +
          this.param(datos.data);
        break;
      case "settlement/download":
        window.location.href =
          this.urlCore + "settlement/download" + this.param(datos.data);
        break;
      case "listAllOperationCashOut/download":
        window.location.href =
          this.urlCore +
          "listAllOperationCashOut/download" +
          this.param(datos.data);
        break;
      case "getstadistict_cashin":
        return this.http.get(
          `${this.urlCore + datos.service + this.param(datos.data)}`,
          header
        );
      case "getstadistict_cashout":
        return this.http.get(
          `${this.urlCore + datos.service + this.param(datos.data)}`,
          header
        );
      case "getstadistict_line_cashin":
        return this.http.get(
          `${this.urlCore + datos.service + this.param(datos.data)}`,
          header
        );
      case "getstadistict_line_cashout":
        return this.http.get(
          `${this.urlCore + datos.service + this.param(datos.data)}`,
          header
        );
      case "merchant/detail":
        // return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
        return this.http.get(
          `${this.urlCore + datos.service + "/" + datos.data}`,
          header
        );
      case "merchant_channel":
        return this.http.put(
          `${this.urlCore + datos.service + "/" + datos.id}`,
          datos.data
        );
      case "merchant/activation_proccess":
        return this.http.post(
          `${this.urlCore + datos.service + "/" + datos.id}`,
          datos.data
        );
      case "merchant_currency":
        return this.http.put(
          `${this.urlCore + datos.service + "/" + datos.id}`,
          datos.data
        );
      case "bank":
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case "createBank":
        return this.http.post(`${this.urlCore + "bank"}`, datos.data);
      case "bankDetail":
        return this.http.get(
          `${this.urlCore + "bank" + "/" + datos.data}`,
          header
        );
      case "User":
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case "profile":
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case "partnerConciliations/listWithParams":
        return this.http.get(`${this.urlCore}partnerConciliations${this.param(datos.data)}`, header)
      case "getTrama":
        return this.http.get(`${this.urlCore}notify/trama/${datos.tramaID}`, header)
      case "v2/payment_order":
        return this.http.post(`${this.urlMultiservi + datos.service}`, datos.data)
      case "updatePartnerConciliations": 
        return this.http.put(`${this.urlCore}partnerConciliations/${datos.partnerID}`, datos.data)
      default:
        break;
    }
  }

  param(object) {
    console.log({ object });
    let parameters = [];
    for (let property in object) {
      if (object.hasOwnProperty(property)) {
        if (
          object[property] != null &&
          object[property] != "" &&
          property != "period"
        ) {
          parameters.push(encodeURI(property + "=" + object[property]));
        }
      }
    }
    console.log("f", "?" + parameters.join("&"));
    return "?" + parameters.join("&");
  }

  getStatus() {
    return [
      {
        name: "Expirado",
        value: "o",
      },
      {
        name: "Pendiente",
        value: "10",
      },
      {
        name: "Anulado",
        value: "11",
      },
      {
        name: "Pagado",
        value: "12",
      },
      {
        name: "Procesado",
        value: "14",
      },
      {
        name: "TimeOut",
        value: "16",
      },
      {
        name: "En espera, proveedor",
        value: "13",
      },
      {
        name: "Validacion 24h",
        value: "15",
      },
      {
        name: "Extorno",
        value: "18",
      },
    ];
  }

  getStatusCashOut() {
    return [
      {
        name: "Anulado",
        value: "o",
      },
      {
        name: "Creado",
        value: "10",
      },
      {
        name: "Aprobación de cliente",
        value: "11",
      },
      {
        name: "En proceso (Payroll)",
        value: "12",
      },
      {
        name: "Error en procesar",
        value: "13",
      },
      {
        name: "Procesado",
        value: "14",
      },
      {
        name: "Notificado",
        value: "15",
      },
      {
        name: "Error al notificar",
        value: "16",
      },
      {
        name: "Reprocesar",
        value: "17",
      },
    ];
  }

  getStatusByID(id) {
    switch (id) {
      case "o":
        return "Expirado";
      case "10":
        return "Pendiente";
      case "11":
        return "Anulado/Rechazado";
      case "12":
        return "Procesado";
      case "13":
        return "Pendiente";
      case "14":
        return "Procesado";
      case "15":
        return "Validacion 24h";
      case "16":
        return "Timeout";
      case "18":
        return "Extornado";
      default:
        return "n/a";
    }
  }

  getStatusByIDCashOut(id) {
    switch (id) {
      case "o":
        return "Anulado";
      case "10":
        return "Creado";
      case "11":
        return "Aprobación de cliente";
      case "12":
        return "En proceso (Payroll)";
      case "13":
        return "Error en procesar";
      case "14":
        return "Procesado";
      case "15":
        return "Notificado";
      case "16":
        return "Error al notificar";
      case "17":
        return "Reprocesar";
      default:
        return "Expirado";
    }
  }

  queryDownload(route: string, params = null) {
    window.location.href = this.urlCore.concat(route) + this.param(params);
  } 

  //1=transactions,2=Billing,3=Gross ,4=Net
  getTypeCashIn() {
    return [
      {
        name: "Transacciones",
        value: "1",
      },
      {
        name: "Facturación",
        value: "2",
      },
      {
        name: "Bruto",
        value: "3",
      },
      {
        name: "Neto",
        value: "4",
      },
    ];
  }
}
