import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  getToken = () => JSON.parse(sessionStorage.getItem('ud')).token;

  getUserDetails = () => JSON.parse(sessionStorage.getItem('ud')).userDetails;
}
