import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss']
})
export class SettlementsComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Reportes > Movimiento saldo';

  titleModal: any = '';
  isVisible: boolean = false;
  contentModal: any = '';
  operationNumber: any = '';
  listBanksCashin: any;


  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'recharge/balance/Conciliation',
      data: []
    }

    let headers = [
      {
        'name': 'Nombre',
        'key': 'MerchantName',
        'opc': true
      },
      {
        'name': 'Saldo actual',
        'key': 'CurrentBalance',
        'opc': true
      },
      {
        'name': 'Total recarga Saldo',
        'key': 'TotalRecharge',
        'opc': true
      },
      {
        'name': 'Total ventas',
        'key': 'TotalSales',
        'opc': true
      },
      
      {
        'name': 'Diferencia',
        'key': 'Difference',
        'opc': true
      },
      {
        'name': 'Reultado',
        'key': 'Result',
        'opc': true
      },
      
    ]

    if (dataInfo !== false) {
      data.service = 'recharge/balance/Conciliation/listWithParams';
      data.data = dataInfo;
    }

    const getData = await this.api.api(data).toPromise();

    for (var i in getData) {
      getData[i].CurrentBalance = new Intl.NumberFormat("en-US").format(getData[i].CurrentBalance);
      getData[i].TotalRecharge = new Intl.NumberFormat("en-US").format(getData[i].TotalRecharge);
      getData[i].TotalSales = new Intl.NumberFormat("en-US").format(getData[i].TotalSales);
      getData[i].Difference = new Intl.NumberFormat("en-US").format(getData[i].Difference);
    } 

    return {
      headers: headers,
      data: getData,
      source: 'activities-cashin-sttlement'
    }

  }



  async getFilters() {

    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    }
    let dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status == 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    let dataStatus = this.api.getStatus();
    /* {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'ID Liquidaciones',
        'key': 'publicid',
        'type': 'text'
      },
      
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
     */
    
     return [

      {
        'name': 'Aliado',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Descargar',
        'key': 'download',
        'type': 'button'
      }

    ];

  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    if (data.type && data.type === 'download') {
      this.downloadReport(data);
    } else {
      this.dataInfo = this.getDataInfo(data);
    }

  }

  functions(e) {
    console.log(e);
  }

  downloadReport(dat) {
    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'settlement/download',
      data: dat.data
    }
    this.api.api(data);
  }

}
