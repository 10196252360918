import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  public details: any = this.processDetail();
  public historical: any = this.processHistorical();
  public activity: any = this.processActivity();
  public titlePage: any;

  constructor(private aR: ActivatedRoute, private api:ApiService, private router: Router) { }

  ngOnInit() {
  }

  async processDetail() {

    const parameters = this.aR.snapshot.paramMap.get('id');
    const id = parameters.split('-')[0];
    const source = parameters.split('-')[1] + '-' + parameters.split('-')[2];

    switch (source) {
      case 'operation-cashin':
        this.titlePage = 'Operation > Cash In > ' + id;

        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'cashin/operations/detail')
        };
      case 'operation-cashout':
        this.titlePage = 'Operation > Cash Out > ' + id;

        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'cashout/operations/detail')
        };
      default:
        break;
    }

  }


  async getDetails(id, service) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: service,
      data: id
    };

    return await this.api.api(data).toPromise();

  }


  getHeadersDetails(source) {

    switch (source) {
      case 'operation-cashin':
        return [
          {
            name: 'Referencia',
            key: 'Transaction-MerchantSalesID'
          },
          {
            name: 'Monto',
            key: 'Transaction-Amount/Transaction-CurrencyCode'
          },
          {
            name: 'Código de comercio',
            key: 'Transaction-MerchantCode'
          },
          {
            name: 'Creación',
            key: 'Transaction-created_at'
          },
          {
            name: 'Cliente',
            key: 'Customer-FullName'
          },
          {
            name: 'Expiración',
            key: 'Transaction-TxExpiration'
          },
        ];

      case 'operation-cashout':
        return [
          {
            name: 'Referencia',
            key: 'Transaction-MerchantReference'
          },
          {
            name: 'Monto',
            key: 'Transaction-Amount/Transaction-CurrencyCode'
          },
          {
            name: 'Código de comercio',
            key: 'Transaction-MerchantCode'
          },
          {
            name: 'Creación',
            key: 'Transaction-TxCreation'
          },
          {
            name: 'Cliente',
            key: 'Customer-FullName'
          },
          {
            name: 'Estado',
            key: 'Transaction-LastStatus'
          },
        ];

        break;

      default:
        break;
    }

  }


  // HISTORICAL

  getHeadersHistorical(source) {

    switch (source) {
      case 'operation-cashin':
        return [
          {
            name: 'Fecha',
            key: 'created_at'
          },
          {
            name: 'Actividad',
            key: 'StatusID'
          }
        ];

      case 'operation-cashout':
        return [
          {
            name: 'Fecha',
            key: 'created_at'
          },
          {
            name: 'Evento',
            key: 'StatusID'
          }
        ];

      default:
        break;
    }

  }

  async processHistorical() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];
    const source = parameters.split('-')[1] + '-' + parameters.split('-')[2];

    switch (source) {
      case 'operation-cashin':

        return await this.getDetails(id, 'cashin/operations/detail').then((data: any) => {

          const activity = [];
          data.Activity.forEach(e => {
            // e.StatusID = this.api.getStatusByID(e.StatusID.toString());
            activity.push(e);
          });

          return {
              headers: this.getHeadersHistorical(source),
              data: activity,
              title: 'Historico:'
          };

        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });

      case 'operation-cashout':

        return await this.getDetails(id, 'cashout/operations/detail').then((data: any) => {

          const activity = [];
          data.Activity.forEach(e => {
            // e.StatusID = this.api.getStatusByID(e.StatusID.toString());
            activity.push(e);
          });

          return {
              headers: this.getHeadersHistorical(source),
              data: activity,
              title: 'Historico:'
          }

        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });

      default:
        break;
    }

  }


  // Activity

  getHeadersActivity(source) {

    switch (source) {
      case 'operation-cashin':
        return [
          {
            name: 'Fecha',
            key: 'created_at'
          },
          {
            name: 'Petición',
            key: 'Request',
            visible: 'Result'
          },
          {
            name: 'Respuesta',
            key: 'Response'
          },
          {
            name: 'Resultado',
            key: 'Result'
          },

        ];

      case 'operation-cashout':
        return [
          {
            name: 'Fecha',
            key: 'created_at'
          },
          {
            name: 'Petición',
            key: 'Request',
            visible: 'Result'
          },
          {
            name: 'Respuesta',
            key: 'Response'
          },
          {
            name: 'Resultado',
            key: 'Result'
          },

        ];
      default:
        break;
    }

  }

  async processActivity() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];
    const source = parameters.split('-')[1] + '-' + parameters.split('-')[2];

    switch (source) {
      case 'operation-cashin':

        return await this.getDetails(id, 'cashin/operations/detail').then((data: any) => {

          return {
            headers: this.getHeadersActivity(source),
            data: data.NotificationActivity,
            title: 'Notificaciones:'
          };

        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });

      case 'operation-cashout':

        return await this.getDetails(id, 'cashout/operations/detail').then((data: any) => {

          return {
            headers: this.getHeadersActivity(source),
            data: data.NotificationActivity,
            title: 'Notificaciones:'
          };

        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });

      default:
        break;
    }

  }




}


