import { InterceptorService } from './services/spinnerServices/interceptor.service';
import { NgxSpinnerModule}from "ngx-spinner";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule,  NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { DashboardComponent } from './components/content/dashboard/dashboard.component';
import { NavComponent } from './components/partials/nav/nav.component';
import { HeaderComponent } from './components/partials/header/header.component';
import { FooterComponent } from './components/partials/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule, NZ_I18N, es_ES } from 'ng-zorro-antd';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { MainMenuComponent } from './components/partials/main-menu/main-menu.component';
import { CashinComponent } from './components/content/operations/cashin/cashin.component';
import { Model1Component } from './components/partials/tables/model1/model1.component';
import { Filter1Component } from './components/partials/filters/filter1/filter1.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { CashoutComponent } from './components/content/operations/cashout/cashout.component';
import { DetailsComponent } from './components/content/operations/details/details.component';
import { Detail1Component } from './components/partials/details/detail1/detail1.component';
import { CommissionCardComponent } from './components/partials/commission-card/commission-card.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ForcePaymentsComponent } from './components/content/activities/cashin/force-payments/force-payments.component';
import { NotificationsComponent } from './components/content/activities/cashin/notifications/notifications.component';
import { SttlementComponent } from './components/content/activities/cashin/sttlement/sttlement.component';
import { SttlementDetailComponent } from './components/content/activities/cashin/sttlement-detail/sttlement-detail.component';
import { BalanceComponent } from './components/content/activities/cashout/balance/balance.component';
import { BalanceDetailComponent } from './components/content/activities/cashout/balance-detail/balance-detail.component';
import { PayrollComponent } from './components/content/activities/cashout/payroll/payroll.component';
import { PayrollDetailComponent } from './components/content/activities/cashout/payroll-detail/payroll-detail.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { OperationsComponent } from './components/content/reports/cashin/operations/operations.component';
import { SettlementsComponent } from './components/content/reports/cashin/settlements/settlements.component';
import { ConciliationsComponent } from './components/content/reports/conciliations/conciliations.component';
import { OperationsCashoutComponent } from './components/content/reports/cashout/operations/operations.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DashboardCashoutComponent } from './components/content/dashboard/dashboard-cashout/dashboard-cashout.component';
import { MerchantsComponent } from './components/configurations/merchants/merchants.component';
import { BanksComponent } from './components/content/settings/banks/banks.component';
import { UserMaintenanceComponent } from './components/content/settings/user-maintenance/user-maintenance.component';
import { ProfileMaintenanceComponent } from './components/content/settings/profile-maintenance/profile-maintenance.component';
// tslint:disable-next-line:max-line-length
import { ProfileMaintenanceDetailComponent } from './components/content/settings/profile-maintenance-detail/profile-maintenance-detail.component';
import { BanksDetailComponent } from './components/content/settings/banks-detail/banks-detail.component';
import { UsersComponent } from './components/configurations/users/users.component';
import { CommerceComponent } from './components/content/settings/commerce/commerce.component';
import { CommerceDetailComponent } from './components/content/settings/commerce-detail/commerce-detail.component';

import { DragDirective } from 'src/app/directive/dragDrop.directive';

import { RecaptchaModule } from 'ng-recaptcha';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ProviderComponent } from './components/content/activities/cashout/provider/provider.component';
import { ProviderDetailComponent } from './components/content/activities/cashout/provider-detail/provider-detail.component';
import { ToolBarFooterComponent } from './components/partials/tool-bar-footer/tool-bar-footer.component';
import { PdfComponent } from './components/partials/pdf/pdf.component';
import { CurrencyPipe } from '@angular/common';
import { ArqueoCajasComponent } from './components/content/arqueo-cajas/arqueo-cajas.component';
import { ExtractComponent } from './components/content/reports/extract/extract.component';
import { FilterComponent } from './components/utils/filter/filter/filter.component';
import { ThousandsPipe } from './pipes/thousands.pipe';
import { GlobalHttpInterceptorService } from '@services/global-http-interceptor.service';
import { Model1PaginationComponent } from './components/partials/tables/model1-pagination/model1-pagination.component';

import { PartnerConciliationComponent } from './components/content/activities/cashin/partner-conciliation/partner-conciliation.component'
import { TablePartnerConciliationComponent } from './components/partials/tables/table-partner-conciliation/table-partner-conciliation.component';

registerLocaleData(es);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    NavComponent,
    HeaderComponent,
    FooterComponent,
    MainMenuComponent,
    CashinComponent,
    Model1Component,
    Model1PaginationComponent,
    Filter1Component,
    CashoutComponent,
    DetailsComponent,
    Detail1Component,
    ForcePaymentsComponent,
    NotificationsComponent,
    SttlementComponent,
    SttlementDetailComponent,
    BalanceComponent,
    BalanceDetailComponent,
    PayrollComponent,
    PayrollDetailComponent,
    OperationsComponent,
    SettlementsComponent,
    ConciliationsComponent,
    OperationsCashoutComponent,
    DashboardCashoutComponent,
    MerchantsComponent,
    BanksComponent,
    UsersComponent,
    DragDirective,
    CommerceComponent,
    CommerceDetailComponent,
    CommissionCardComponent,
    BanksDetailComponent,
    UserMaintenanceComponent,
    ProfileMaintenanceComponent,
    ProfileMaintenanceDetailComponent,
    ProviderComponent,
    ProviderDetailComponent,
    ToolBarFooterComponent,
    PdfComponent,
    ArqueoCajasComponent,
    ExtractComponent,
    FilterComponent,
    ThousandsPipe,
    PartnerConciliationComponent,
    TablePartnerConciliationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    BrowserAnimationsModule,
    NzMenuModule,
    NzTableModule,
    NzFormModule,
    NzModalModule,
    NzDatePickerModule,
    NzIconModule,
    NgxChartsModule,
    RecaptchaModule,
    NgCircleProgressModule.forRoot({}),
    NgApexchartsModule,
    CommonModule,
    NgxSpinnerModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiService,
      multi: true,
    },
    {
      provide: NZ_I18N, useValue: es_ES
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true,
    },
    CurrencyPipe
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
