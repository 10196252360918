import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MessageErrorService } from './../../../../../services/utils/message-error.service';
import { BalanceService } from './../../../../../services/balance/balance.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
// import * as jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

@Component({
  selector: 'app-balance-detail',
  templateUrl: './balance-detail.component.html',
  styleUrls: ['./balance-detail.component.css']
})
export class BalanceDetailComponent implements OnInit {

  public details: any;
  public historical: any;
  public titlePage: any;
  public from_date = null;
  public to_date = null;
  modalDevolucion: boolean = false;
  formReverseBalance: FormGroup;
  titleModal: string = '';
  contentModal: any = '';
  okText:any =  '';
  dataEventModal: any;
  MerchantFinanceActivityID:string;
  messageModal: string;
  dataReverse: any;
  // public filters: any = this.getFilters();

  constructor(
    private aR: ActivatedRoute, 
    private api: ApiService, 
    private router: Router, 
    private route: ActivatedRoute,
    private balanceService: BalanceService,
    private messageError: MessageErrorService,
    private notification: NzNotificationService
    ) {
    // this.downloadPDF();
    this.formReverseBalance = this.createFormReverse();
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.from_date = params.from_date ? params.from_date : null;
      this.to_date = params.to_date ? params.to_date : null;
    });
    this.details = this.processDetail()
    this.historical = this.processHistorical();
  }

  async processDetail() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];
    this.titlePage = 'Aliado > Movimientos entrada > ' + id;

    return {
      headers: this.getHeadersDetails(),
      data: this.getDetails(id, 'balance/detail')
    };
  }


  async getDetails(id, service) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: service,
      data: {
        id,
        filters: {
          from_date: this.from_date,
          to_date: this.to_date
        }
      }
    };
    return await this.api.api(data).toPromise();
  }


  getHeadersDetails() {
  return [
    {
      name: 'NumberAccount',
      key: 'Balance-NumberAccount'
    },
    {
      name: 'Total Amount',
      key: 'Balance-TotalAmount'
    },
    {
      name: 'Amount Available',
      key: 'Balance-AmountAvailable'
    },
    {
      name: 'Currency',
      key: 'Balance-CurrencyCode'
    },
    {
      name: 'Active',
      key: 'Balance-IsActive'
    },
    {
      name: 'Updated Time',
      key: 'Balance-updated_at'
    }
  ];
}


  // HISTORICAL

  /*async*/ getHeadersHistorical ()/*(dataInfo: any = false)*/ {
    // const data = {
    //   token: JSON.parse(sessionStorage.getItem('ud')).token,
    //   service: 'balance/detail',
    //   data: []
    // };

    /*const headers = */ return [
      {
        name: 'Fecha registro',
        key: 'created_at'
      },
      {
        name: 'Total recarga',
        key: 'Amount'
      },
      {
        name: 'Moneda',
        key: 'CurrencyCode'
      },
      {
        name: 'Banco',
        key: 'ShortName'
      },
      {
        name: 'Referencia',
        key: 'Reference'
      },
      {
        name: 'PDF',
        key: 'Download'
      },
      {
        name: 'Acciones',
        key: 'actions'
      }
    ];

    // if (dataInfo !== false) {
    //   data.service = 'balance/detailParams';
    //   dataInfo.MerchantType = 6;
    //   data.data = dataInfo;
    // }
    // const getData = await this.api.api(data).toPromise() as any[];
    // return {
    //   headers: headers,
    //   data: getData,
    //   source: 'balance-detail'
    // };
  }

  async processHistorical() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];

    return await this.getDetails(id, 'balance/detail').then((data: any) => {
      console.log("historic DATA: ", data)
      const activity = [];
      data.Historico.forEach(e => {
        e.Amount = new Intl.NumberFormat("en-US").format(e.Amount);
        activity.push(e);
      });

      return {
          headers: this.getHeadersHistorical(),
          data: activity,
          title: 'Historico:'
      };
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

    // /*-----------PDF-----------*/
    // downloadPDF() {
    //   // Extraemos el
    //   const DATA = document.getElementById('htmlData');
    //   const doc = new jsPDF('p', 'pt', 'a4');
    //   const options = {
    //     background: 'white',
    //     scale: 3
    //   };
    //   html2canvas(DATA, options).then((canvas) => {
  
    //     const img = canvas.toDataURL('image/PNG');
  
    //     // Add image Canvas to PDF
    //     const bufferX = 15;
    //     const bufferY = 15;
    //     const imgProps = (doc as any).getImageProperties(img);
    //     const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //     doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //     return doc;
    //   }).then((docResult) => {
    //     docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
    //   });
    // }
    // /*-----FILTER-----*/

    // async getFilters(){

    //   const data = {
    //     token: JSON.parse(sessionStorage.getItem('ud')).token,
    //     service: 'merchants'
    //   };
  
    //   const dataStatus = this.api.getStatus();
  
    //   const dataMerchant = [];
    //   await this.api.api(data).toPromise().then((result: any) => {
  
    //     result.forEach(element => {
    //       dataMerchant.push({
    //         name: element.BussinesName,
    //         value: element.MerchantCode,
    //       });
    //     });
    //   }).catch(error => {
    //     if (error.status === 401) {
    //       sessionStorage.setItem('ud', '');
    //       this.router.navigate(['/']);
    //     }
    //   });
  
    //   return [
    //     {
    //       'name': 'Código de pago',
    //       'key': 'paymentCode',
    //       'type': 'text'
    //     },
    //     {
    //       'name': 'Estado',
    //       'key': 'status',
    //       'type': 'select',
    //       'data': dataStatus
    //     },
    //     {
    //       'name': 'Referencia Comercial',
    //       'key': 'merchantSalesID',
    //       'type': 'text'
    //     },
    //     {
    //       'name': 'N° Operación',
    //       'key': 'PublicId',
    //       'type': 'text'
    //     },
    //     {
    //       'name': 'Periodo',
    //       'key': ['from_date','to_date'],
    //       'type': 'period'
    //     },
    //     {
    //       'name': 'Comercio',
    //       'key': 'mcode',
    //       'type': 'select',
    //       'data': dataMerchant
    //     }
  
    //   ];
  
    // }

  eventsOutput(args:any){
    switch (args.function) {
      case 'reverse':
        console.log('dataReverse', args.data);
        if(!this.isCreatetAtValid(args.data.created_at))
          this.notification.create('error', '','No es posible realizar una devolucion sobre la transaccion.');
        else {
          this.titleModal = 'Retraer Saldo';
          this.contentModal = "newBalanceReverse";
          this.dataEventModal = "newBalanceReverse";
          this.modalDevolucion = true;
          this.dataReverse = args.data;
  
          this.MerchantFinanceActivityID = this.dataReverse.MerchantFinanceActivityID;
            this.formReverseBalance.get("Reference").setValue(this.dataReverse.Reference);
            this.formReverseBalance.get("BankCode").setValue(this.dataReverse.BankCode);
            this.formReverseBalance.get("BankName").setValue(this.dataReverse.ShortName);
            this.formReverseBalance
              .get("MerchantCode")
              .setValue(this.dataReverse.Invoice.MerchantCode);
            this.formReverseBalance.get("LegalName").setValue(this.dataReverse.Invoice.LegalName);
            this.formReverseBalance.get("Amount").setValue(this.dataReverse.Amount);
            this.formReverseBalance.get("CurrencyId").setValue(this.dataReverse.CurrencyCode);
        }
        break;
    
      default:
        break;
    }
  }

  isCreatetAtValid(created_at) {
    const yesterday = new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10);
    return !(created_at < yesterday);
  }

  showModalRefund(): void {
    this.modalDevolucion = true;
  }

  handleCancel(): void {
    this.modalDevolucion = false;
    this.formReverseBalance.reset();
    this.dataReverse = null;
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case 'newBalanceReverse':
        if( !this.formReverseBalance.valid ) {
          this.messageError.showValidationMsg(this.formReverseBalance);
          break;
        }
        this.balanceService.cashoutReverse(this.MerchantFinanceActivityID,this.formReverseBalance.value).subscribe((response:any) => {
          this.notification.create('success', response.Message, '');
          this.handleCancel();
        }, error => {
          this.notification.create('error', 'Ha ocurrido un error', error.error.Message);
        }); 
        break;
    
      default:
        break;
    }
  }

  createFormReverse() {
    return new FormGroup({
      MerchantCode: new FormControl(''),
      LegalName: new FormControl(''),
      BankCode: new FormControl(''),
      BankName: new FormControl(''),
      Reference: new FormControl('', [Validators.required]),
      Amount: new FormControl(''),
      CurrencyId: new FormControl('COP'),
      description: new FormControl('', [Validators.required])
    });
  }
}
