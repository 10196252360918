import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  user: any = "";

  constructor(private api: ApiService, private router:Router) { }

  ngOnInit() {
    console.log('sessionStorage', sessionStorage.length);
    if(!sessionStorage.length) {
      sessionStorage.setItem('ud', '');
      this.router.navigate(['/login']);
    }
    this.user = JSON.parse(sessionStorage.getItem('ud')).userDetails;
  }

  exit(){
    
    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'auth/logout'
    }
   
    this.api.api(data).subscribe((result:any)=> {
      if (result.status == true){
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/login']);
      }
    },
      error => {
        if (error.error.message == 'Token has expired'){
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/login']);
        }else{
          console.log(error);
        }
      });
  }

}
