import { HttpService } from './../http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  constructor(
    private http: HttpService 
  ) { }

  getDetailCashoutOperation(reference) {
    return this.http.get(`cashout/operations/${reference}`);
  }

  getDetailCashinOperation(reference) {
    return this.http.get(`cashin/operations/${reference}`);
  }

  cashinReverse(ProviderBalanceActivityID, data) {
    return this.http.post(`cashin/operations/${ProviderBalanceActivityID}/reverse`, data);
  }

  cashoutReverse(MerchantFinanceActivityID, data) {
    return this.http.post(`cashout/operations/${MerchantFinanceActivityID}/reverse`, data);
  }

  cashoutReturn(Merchant, data) {
    return this.http.post(`cashout/operations/${Merchant}/return-balance`, data);
  }
}
