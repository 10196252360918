const filters = [
    {
        "name": "Periodo",
        "key": ["from_date", "to_date"],
        "type": "period"
    },
    {
        "name": "Comercio",
        "key": "mcode",
        "type": "select",
        "data": [

        ]
    },
    {
        name: "Descargar",
        key: "download",
        type: "button",
    }
];

export default filters;