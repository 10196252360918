import { MessageErrorService } from "./../../../../../services/utils/message-error.service";
import { BalanceService } from "./../../../../../services/balance/balance.service";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ProviderService } from "@services/provider/provider.service";

@Component({
  selector: "app-provider",
  templateUrl: "./provider.component.html",
  styleUrls: ["./provider.component.scss"],
})
export class ProviderComponent implements OnInit {
  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = "Actividades > Control Saldo > proveedores";

  titleModal: any = "";
  isVisible: boolean = false;
  contentModal: any = "";
  operationNumber: any = "";
  listBanksCashin: any;
  formNewBalance: FormGroup;
  formRetractBalance: FormGroup;
  formBagType: FormGroup;
  okText: any = "";
  messageModal: string;
  dataEventModal: any;
  dataNewBalance: any;
  isAlertVisible = false;
  isAlertErrorVisible = false;
  dataMerchant: any;
  errorMessage = "";
  modalDevolucion: boolean = false;
  ProviderBalanceActivityID: string;
  modalBagType: boolean = false;
  providerID: number;
  bagType: number;

  constructor(
    private api: ApiService,
    private router: Router,
    private balanceService: BalanceService,
    private notification: NzNotificationService,
    private messageError: MessageErrorService,
    private providerService: ProviderService
  ) {
    this.formNewBalance = this.createFormGroup();
    this.formRetractBalance = this.createFormRetraction();
    this.formBagType = this.createFormBagType();
  }

  ngOnInit() { }

  createFormGroup() {
    return new FormGroup({
      MerchantCode: new FormControl("1001"),
      BankCode: new FormControl("1001"),
      Reference: new FormControl(""),
      Amount: new FormControl(""),
      CurrencyId: new FormControl("COP"),
    });
  }
  createFormBagType() {
    return new FormGroup({
      // ProviderID: new FormControl("", [Validators.required]),
      BagType: new FormControl("", [
        Validators.required,
        // Validators.pattern("'^[1-2]$]'"),
      ]),
    });
  }

  createFormRetraction() {
    return new FormGroup({
      MerchantCode: new FormControl("1001"),
      BankCode: new FormControl("1001"),
      Reference: new FormControl("", [Validators.required]),
      Amount: new FormControl(""),
      CurrencyId: new FormControl("COP"),
      description: new FormControl("", [Validators.required]),
    });
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "provider/balance",
      data: [],
    };

    const headers = [
      {
        name: "Provider Code",
        key: "ProviderCode",
        opc: true,
      },
      {
        name: "Provider",
        key: "BussinesName",
        opc: true,
      },
      {
        name: "Saldo",
        key: "CurrentBalance",
        opc: false,
        functions: ["Balance"],
      },
      {
        name: "Saldo Minimum",
        key: "MinAmount",
        opc: true,
      },
      {
        name: "Currency",
        key: "CurrencyCode",
        opc: true,
      },
      {
        name: "Is Active",
        key: "Active",
        opc: true,
      },
      {
        name: "Actividades",
        key: "actividadbalanceprovider",
        opc: "true",
      },
    ];

    if (dataInfo !== false) {
      data.service = "provider/listBalanceWithParams";
      data.data = dataInfo;
    }
    const getData = await this.api.api(data).toPromise();
    for (var i in getData) {
      getData[i].CurrentBalance = new Intl.NumberFormat("en-US").format(
        getData[i].CurrentBalance
      );
      getData[i].MinAmount = new Intl.NumberFormat("en-US").format(
        getData[i].MinAmount
      );
    }
    return {
      headers: headers,
      data: getData,
      source: "activities-balance-provider",
    };
  }

  async getFilters() {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "provider",
    };
    const dataMerchant = [];
    await this.api
      .api(data)
      .toPromise()
      .then((result: any) => {
        result.forEach((element) => {
          dataMerchant.push({
            name: element.BussinesName,
            value: element.ProviderCode,
          });
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
        }
      });

    const dataStatus = this.api.getStatus();
    this.dataMerchant = dataMerchant;

    return [
      {
        name: "Proveedor",
        key: "providercode",
        type: "select",
        data: dataMerchant,
      },
      // {
      //   'name': 'Nuevo balance',
      //   'key': 'new-balance',
      //   'type': 'button',
      // }
      {
        key: "actions",
        type: "actions",
        data: [
          {
            name: "Recargar",
            key: "new-balance",
            type: "button",
          },
          // {
          //   'name': 'Devolucion',
          //   'key': 'new-refund',
          //   'type': 'button'
          // }
        ],
      },
    ];
  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    switch (data) {
      case "new-balance":
        this.getDataBankCashin()
          .then((result: any) => {
            this.listBanksCashin = result;
            this.titleModal = "Nuevo Balance";
            this.contentModal = "newBalance";
            this.dataEventModal = "newBalance";
          })
          .catch((error) => {
            alert(error.message);
            if (error.status === 401) {
              sessionStorage.setItem("ud", "");
              this.router.navigate(["/"]);
            }
          });
        this.showModal();
        this.okText = "Crear";
        break;

      case "new-refund":
        this.getDataBankCashin()
          .then((result: any) => {
            this.listBanksCashin = result;
            this.titleModal = "Refund Balance";
            this.contentModal = "newBalanceRetraction";
            this.dataEventModal = "newBalanceRetraction";
          })
          .catch((error) => {
            alert(error.message);
            if (error.status === 401) {
              sessionStorage.setItem("ud", "");
              this.router.navigate(["/"]);
            }
          });
        this.showModalRefund();
        this.okText = "Crear";
        break;
      default:
        this.dataInfo = this.getDataInfo(data);
        break;
    }
  }

  functions(e) {
    switch (e.function) {
      case "updateBagType":
        this.modalBagType = true;
        this.providerID = e.data.ProviderID;
        this.bagType = e.data.BagType;
        break;

      default:
        break;
    }
  }

  async getDataBankCashin() {
    let data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "listBanksCashin",
    };
    return await this.api.api(data).toPromise();
  }

  async newBalance() {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "provider/balance/update",
      data: {
        ProviderCode: this.MerchantCode.value,
        BankCode: this.BankCode.value,
        Reference: this.Reference.value,
        Amount: this.Amount.value,
        CurrencyCode: this.CurrencyId.value,
        Metadata: JSON.stringify(
          JSON.parse(sessionStorage.getItem("ud")).userDetails
        ),
      },
    };
    return await this.api.api(data).toPromise();
  }

  getDetailCashinOperation({ target }) {
    this.balanceService.getDetailCashinOperation(target.value).subscribe(
      (data: any) => {
        if (data) {
          this.ProviderBalanceActivityID = data.ProviderBalanceActivityID;
          this.formRetractBalance
            .get("MerchantCode")
            .setValue(data.provider.ProviderCode);
          this.formRetractBalance.get("BankCode").setValue(data.bank.BankCode);
          this.formRetractBalance.get("Amount").setValue(data.Amount);
          this.formRetractBalance.get("CurrencyId").setValue(data.CurrencyCode);
        }
      },
      (error) => {
        this.notification.create(
          "error",
          "Ha ocurrido un error",
          error.error.Message
        );
      }
    );
  }

  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  showModalRefund(): void {
    this.modalDevolucion = true;
  }

  handleAlertNotification(): void {
    this.isAlertVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formNewBalance = this.createFormGroup();
    var that = this;
    setTimeout(function() {
      that.isAlertVisible = false;
    }, 3000);
  }

  handleAlertErrorNotification(): void {
    this.isAlertErrorVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formNewBalance = this.createFormGroup();
    var that = this;
    setTimeout(function() {
      that.isAlertErrorVisible = false;
      that.errorMessage = "";
    }, 3000);
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case "newBalance":
        this.newBalance()
          .then((result: any) => {
            if (result.message === "New balance updated successfull") {
              this.handleAlertNotification();
            } else {
              this.errorMessage = result.message;
              this.handleAlertErrorNotification();
            }
            this.isVisible = false;
            this.okText = "Aceptar";
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.handleAlertErrorNotification();
            console.log("ERROR", error);
            if (error.status === 401) {
              sessionStorage.setItem("ud", "");
              this.router.navigate(["/"]);
            }
          });
        break;
      case "newBalanceRetraction":
        if (!this.formRetractBalance.valid) {
          this.messageError.showValidationMsg(this.formRetractBalance);
          break;
        }
        this.balanceService
          .cashinReverse(
            this.ProviderBalanceActivityID,
            this.formRetractBalance.value
          )
          .subscribe(
            (response: any) => {
              this.notification.create("success", response.Message, "");
              this.handleCancel();
            },
            (error) => {
              this.notification.create(
                "error",
                "Ha ocurrido un error",
                error.error.Message
              );
            }
          );
        break;
      default:
        break;
    }
  }

  handleCancel(): void {
    this.formNewBalance = this.createFormGroup();
    this.isVisible = false;
    this.modalDevolucion = false;
    this.formRetractBalance.reset();
  }

  get MerchantCode() {
    return this.formNewBalance.get("MerchantCode");
  }
  get BankCode() {
    return this.formNewBalance.get("BankCode");
  }
  get Reference() {
    return this.formNewBalance.get("Reference");
  }
  get Amount() {
    return this.formNewBalance.get("Amount");
  }
  get CurrencyId() {
    return this.formNewBalance.get("CurrencyId");
  }

  handleOkBagType(): void {
    if (!this.formBagType.valid) {
      return;
    }

    this.providerService
      .updateBagType(this.providerID, {
        BagType: this.formBagType.get("BagType").value,
      })
      .subscribe(
        (response: any) => {
          this.notification.create("success", response.Message, "");
          this.handleCancel();
        },
        (error) => {
          this.notification.create(
            "error",
            "Ha ocurrido un error",
            error.error.Message
          );
        }
      );
  }
}
