import { RegisterComponent } from './components/auth/register/register.component';
import { LoginComponent } from './components/auth/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { PdfComponent } from './components/partials/pdf/pdf.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent, pathMatch: 'full' },
  { path: 'operations-cashin', component: HomeComponent, pathMatch: 'full' },
  { path: 'operations-cashout', component: HomeComponent, pathMatch: 'full' },
  { path: 'operations-details/:id', component: HomeComponent, pathMatch: 'full' },
  { path: 'activities-cashin-forcepayment', component: HomeComponent, pathMatch: 'full' },
  { path: 'activities-cashin-notifications', component: HomeComponent, pathMatch: 'full' },
  { path: 'activities-cashin-sttlement', component: HomeComponent, pathMatch: 'full' },
  { path: 'activities-cashin-partner-conciliation', component: HomeComponent, pathMatch: 'full' },
  { path: 'sttlement-detail/:id', component: HomeComponent, pathMatch: 'full' },
  
  { path: 'activities-cashout-balance', component: HomeComponent, pathMatch: 'full' },
  { path: 'balance-detail/:id', component: HomeComponent, pathMatch: 'full' },
  
  { path: 'activities-balance-provider', component: HomeComponent, pathMatch: 'full' },
  { path: 'provider-detail/:id', component: HomeComponent, pathMatch: 'full' },

  { path: 'activities-cashout-payroll', component: HomeComponent, pathMatch: 'full' },
  { path: 'payroll-detail/:id', component: HomeComponent, pathMatch: 'full' },
  { path: 'reports-cashin-operations', component: HomeComponent, pathMatch: 'full' },
  { path: 'reports-cashin-settlements', component: HomeComponent, pathMatch: 'full' },
  { path: 'reports-cashout-operations', component: HomeComponent, pathMatch: 'full' },
  { path: 'reports-extract', component:HomeComponent, pathMatch: 'full'},
  { path: 'reports-conciliations', component: HomeComponent, pathMatch: 'full' },
  { path: 'dashboard-cashin', component: HomeComponent, pathMatch: 'full' },
  { path: 'dashboard-cashout', component: HomeComponent, pathMatch: 'full' },
  { path: 'settings-commerce', component: HomeComponent, pathMatch: 'full' },
  { path: 'commerce-detail', component: HomeComponent, pathMatch: 'full' },
  { path: 'commerce-detail/:id/:view', component: HomeComponent, pathMatch: 'full' },
  { path: 'settings-banks', component: HomeComponent, pathMatch: 'full' },
  { path: 'banks-detail', component: HomeComponent, pathMatch: 'full' },
  { path: 'banks-detail/:id/:view', component: HomeComponent, pathMatch: 'full' },
  { path: 'user-maintenance', component: HomeComponent, pathMatch: 'full' },
  { path: 'profile-maintenance', component: HomeComponent, pathMatch: 'full' },
  { path: 'maintenance-profile-detail', component: HomeComponent, pathMatch: 'full' },
  { path: 'maintenance-profile-detail/:id', component: HomeComponent, pathMatch: 'full' },
  { path: 'pdf/:id', component: PdfComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
