import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commerce',
  templateUrl: './commerce.component.html',
  styleUrls: ['./commerce.component.css']
})
export class CommerceComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  initialDataInfo: any;
  updatedDataInfo: any;
  isFirstTime = true;

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchant',
      data: []
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'MerchantCode',
        'opc': true
      },
      {
        'name': 'Name',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Web',
        'key': 'WebPage',
        'opc': true
      },
      {
        'name': 'Status',
        'key': 'Status',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit'],
      }
    ];

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
    };
  }

  handleOpcionEvents(list) {
    if (list.function === 'Edit') {
      this.router.navigate(['/commerce-detail/' + list.data.MerchantCode +  '/edit']);
    } else if (list.function === 'View') {
      this.router.navigate(['/commerce-detail/' + list.data.MerchantCode +  '/view']);
    }
  }

  newItem() {
    this.router.navigate(['/commerce-detail/']);
  }

  handleSearch(e) {

    const temporalList = [];
    const inputValue = e.target.value.trim();
    const headers = [
      {
        'name': 'ID',
        'key': 'MerchantCode',
        'opc': true
      },
      {
        'name': 'Name',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Web',
        'key': 'WebPage',
        'opc': true
      },
      {
        'name': 'Status',
        'key': 'Status',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit', 'View'],
      }
    ];

    if (this.isFirstTime) {
      // this.initialDataInfo = this.dataInfo;
      this.initialDataInfo = [...this.dataInfo.__zone_symbol__value.data];
      this.isFirstTime = false;
    }

    if (inputValue === '') {
      // this.dataInfo = this.initialDataInfo;
      this.dataInfo = [...this.initialDataInfo.dataInfo.__zone_symbol__value.data];
    } else {

      this.dataInfo.__zone_symbol__value.data  = [];
      this.dataInfo.__zone_symbol__value.data.length = 0;

      for (let i = 0 ; i < this.initialDataInfo.length ; i++) {
        if (  this.isValuePresent(this.initialDataInfo[i].MerchantCode.toString(), inputValue) ||
              this.isValuePresent(this.initialDataInfo[i].BussinesName, inputValue) ||
              this.isValuePresent(this.initialDataInfo[i].LegalNumber, inputValue) ||
              this.isValuePresent(this.initialDataInfo[i].WebPage, inputValue)) {

            this.dataInfo.__zone_symbol__value.data = [
              ...this.dataInfo.__zone_symbol__value.data,
              {
                    MerchantCode: this.initialDataInfo[i].MerchantCode,
                    BussinesName: this.initialDataInfo[i].BussinesName,
                    LegalNumber: this.initialDataInfo[i].LegalNumber,
                    WebPage: this.initialDataInfo[i].WebPage,
                    Status: this.initialDataInfo[i].Status,
              }
              // this.dataInfo.


              // {
              //   MerchantCode: this.initialDataInfo[i].MerchantCode,
              //   BussinesName: this.initialDataInfo[i].BussinesName,
              //   LegalNumber: this.initialDataInfo[i].LegalNumber,
              //   WebPage: this.initialDataInfo[i].WebPage,
              //   Status: this.initialDataInfo[i].Status,
              // }
            ];

            // temporalList.push(this.dataInfo.__zone_symbol__value.data[i]);
        }
      }

      // if (this.dataInfo.length === 0) {

      //   this.dataInfo = [];
      //   this.dataInfo.length = 0;


      //   this.dataInfo = [
      //     ...this.dataInfo,
      //     {
      //       id: `${this.i}`,
      //       name: `Edward King ${this.i}`,
      //       age: '32',
      //       address: `London, Park Lane no. ${this.i}`
      //     }
      //   ];

      //   this.dataInfo.__zone_symbol__value.data = temporalList;
      //   // this.dataInfo = this.updatedDataInfo;
      // }

    }
  }

  isValuePresent(list, item) {
    return list.toLocaleLowerCase().indexOf(item.toLocaleLowerCase()) > -1;
  }

}
