import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-profile-maintenance',
  templateUrl: './profile-maintenance.component.html',
  styleUrls: ['./profile-maintenance.component.css']
})
export class ProfileMaintenanceComponent implements OnInit {

  public dataAdminInfo: any = this.getDataInfo('profile/admin');
  public dataMerchantInfo: any = this.getDataInfo('profile/merchant');

  isAdministradorSelected = true;
  isMerchantSelected = false;

  isCreateAdministradorModalVisible = false;
  isCreatMerchantModalVisible = false;

  isRemoveModalVisible = false;
  isAddUserModalVisible = false;

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  selectAdministradorOption() {
    this.resetOptions();
    this.isAdministradorSelected = true;
  }

  selectMerchantOption() {
    this.resetOptions();
    this.isMerchantSelected = true;
  }

  resetOptions() {
    this.isAdministradorSelected = false;
    this.isMerchantSelected = false;
  }

  newItem() {
    const userType = (this.isAdministradorSelected) ? 'admin' : 'merchant' ;
    this.router.navigate(['/maintenance-profile-detail/' +  userType]);
  }

  async getDataInfo(dataType: string) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: dataType,
      data: []
    };

    const headers = [
      {
        'name': 'Name',
        'key': 'VisibleName',
        'opc': true
      },
      {
        'name': 'Rol Name',
        'key': 'NameProfile',
        'opc': true
      },
      {
        'name': 'Total Users',
        'key': 'TotUser',
        'opc': true
      },
      {
        'name': 'Capacity',
        'key': 'Capacity',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit', 'Remove', 'Add User']
      }
    ];

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
    };
  }

  handleOpcionEvents(list) {
    // this.staggeredEditListData = list.data;
    if (list.function === 'Edit') {
      this.newItem();
    }
    if (list.function === 'Remove') {
      this.isRemoveModalVisible = true;
    }
    if (list.function === 'Add User') {
      this.isAddUserModalVisible = true;
    }
  }

  closeRemoveUserdModal() {
    this.isRemoveModalVisible = false;
  }

  saveRemoveUserModal() {
    // TODO CALL CONFIRM SERVICE
    this.isRemoveModalVisible = false;
  }

  closeAddUserdModal() {
    this.isAddUserModalVisible = false;
  }

  saveAddUserModal() {
    // TODO CALL CONFIRM SERVICE
    this.isAddUserModalVisible = false;
  }

}
