import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-conciliations',
  templateUrl: './conciliations.component.html',
  styleUrls: ['./conciliations.component.scss']
})
export class ConciliationsComponent implements OnInit {

  from_date: any = moment().subtract(6, 'days').format('YYYY-MM-DD');
  to_date: any = moment().add(1, 'days').format('YYYY-MM-DD');
  selectedCurrency = 'PEN';
  isRangeModalVisible = false;
  titleModal: any = '';
  merchantList: any;
  isSummaryModalVisible = false;
  titleSummaryModal = '';
  dataList: any;

  styleDate = {
    'min-width': '200px',
    'padding-right': '20px'
  };


  constructor(private aR: ActivatedRoute, private api: ApiService, private router: Router) { }

  ngOnInit() {
    this.getFilters();
    this.getData();
    Object.assign(this, this.dataList);
  }

  getFilters() {

    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    };

    this.api.api(data).toPromise().then((result: any) => {

      this.merchantList = result;

      // result.forEach(element => {
      //   this.merchantList.push({
      //     name: element.BussinesName,
      //     value: element.MerchantCode,
      //   });
      // });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  async getData() {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'conciliation/info',
      data: {
        from_date: moment(this.from_date).format('YYYY-MM-DD'),
        to_date: moment(this.to_date).format('YYYY-MM-DD'),
        mcode: '1001',
        currency_code: this.selectedCurrency
      }
    };

    await this.api.api(data).toPromise().then((result: any) => {
      this.dataList = result;
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  selectChangeHandler(event: any) {
    this.selectedCurrency = event.target.value;
    this.getData();
  }

  onChangePeriod(e) {

    // moment.locale('es', {
    //   week: {
    //     dow: 1,
    //     doy: 4
    //   }
    // });

    let fromDate = '';
    let toDate = moment().format('YYYY-MM-DD');

    switch (e.target.value) {
      case '1':
        fromDate = moment().subtract(1, "days").format('YYYY-MM-DD');
        break;
      case '2':
        fromDate = moment().weekday(0).format('YYYY-MM-DD');
        break;
      case '3':
        fromDate = moment().startOf('month').format('YYYY-MM-DD');
        break;
      case '4':
        fromDate = moment().subtract(3, "months").startOf('month').format('YYYY-MM-DD');
        break;
      case '5':
        this.isRangeModalVisible = true;
        break;
      default:
        break;
    }

    this.from_date  = fromDate;
    this.to_date = toDate;
    console.log('FROM: ', fromDate);
    console.log('TO: ', toDate);
    this.getData();
  }

  handleOk(): void {
    this.isRangeModalVisible = false;
    this.getData();
  }

  onChangeDate(result: Date): void {
    this.from_date = moment(result[0]).format('YYYY-MM-DD');
    this.to_date = moment(result[1]).format('YYYY-MM-DD');
    this.getData();
  }

  seeSummary() {
    this.isSummaryModalVisible = true;
  }

  handleSummaryOk() {
    this.isSummaryModalVisible = false;
  }

}
