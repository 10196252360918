import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

    this.marginNav();

  }

  openMap: { [name: string]: boolean } = {
    sub1: false,
    sub2: false,
    sub3: false
  };

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }

  marginNav(){
    let i = 0;
    let marginNav = setInterval(function () {
      if ($('.ng-star-inserted').length > 0) {
        // $('.ng-star-inserted').addClass('row');
        // $('.ng-star-inserted span').addClass('spanNav');
        clearInterval(marginNav);
      } else {
        if (i == 50) {
          clearInterval(marginNav);
        }
        i++
      }
    }, 200);
  }


  navigation(route){
    console.log({route});
    this.router.navigate(['/' + route]);
  }

}
