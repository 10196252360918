import { MerchantService } from "./../../../../services/merchant/merchant.service";
import { PaginateInterface } from "./../../../../interfaces/paginate-interface";
import { FormBuilder, FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ExtractService } from "./../../../../services/extract/extract.service";
import { Component, OnInit } from "@angular/core";
import filters from "../../../../../assets/config/filters-extract.js";

@Component({
  selector: "app-extract",
  templateUrl: "./extract.component.html",
  styleUrls: ["./extract.component.scss"],
})
export class ExtractComponent implements OnInit {
  formFilter: FormGroup;
  records: any;
  loading: boolean = true;
  total: number = 1;
  per_page: number = 10;
  current_page: number = 1;
  today = new Date();
  filters: any;
  merchants: any;
  totals: any;
  defaultMerchantCode;
  numberAccount = null;

  constructor(
    private extractService: ExtractService,
    private fb: FormBuilder,
    private merchantService: MerchantService
  ) {
  }
  
  ngOnInit() {
    this.filters = this.getFilters();
    this.buildFormFilters();
    this.getData();
    this.getTotals();
  }

  buildFormFilters() {
    const year = this.today.getFullYear();
    const month = this.today.getMonth();
    this.formFilter = this.fb.group({
      from_date: new FormControl(
        `${month > 0 ? year : year - 1}-${month > 0 ? ("0" + (month).toString().slice(-2)) : 12}-${(
          "0" + this.today.getDate()
        ).slice(-2)}`
      ),
      to_date: new FormControl(""),
      page: new FormControl(""),
      size: new FormControl("10"),
      MerchantType: new FormControl("6"),
    });
  }

  getData(mcode = this.defaultMerchantCode) {
    this.extractService
      .getRecords(mcode, this.formFilter.value)
      .subscribe((response: PaginateInterface) => {
        this.records = response;
        this.loading = false;
      });
  }

  downloadData(mcode = this.defaultMerchantCode) {
    this.extractService
      .downloadRecords(mcode, this.formFilter.value)
      .subscribe((response: any) => {
        var newBlob = new Blob([response]);
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        const date = new Date();
        link.download = `Extract.${date.toISOString()}.csv`
        link.href = url;
        link.click();
      });
  }

  getTotals(mcode = this.defaultMerchantCode) {
    this.extractService
      .getTotals(mcode, this.formFilter.value)
      .subscribe((response: any) => (this.totals = response));
  }

  getFilters() {
    this.getMerchants();
    return new Promise((resolve, reject) => {
      const localFilters = filters.map((filter) => {
        if (filter.name === "Comercio") {
          filter.data = [];
          this.merchants.forEach((merchant, key) => {
            filter.data.push({
              name: merchant.BussinesName,
              value: merchant.MerchantCode,
              selected: key == 0 ? true : false,
              numberAccount: merchant.NumberAccount
            });
            if(key == 0) {
              this.defaultMerchantCode = merchant.MerchantCode
              this.numberAccount = merchant.NumberAccount;
            }
          });
        }
        return filter;
      });
      resolve(localFilters);
    });
  }

  getMerchants() {
    this.merchantService.getMerchants().subscribe((data:any) => {
      this.merchants = data;
    });
  }

  onPageIndexChange(page): void {
    this.formFilter.controls.page.setValue(page);
    this.getData();
    this.getTotals();
  }

  onPageSizeChange(size): void {
    this.formFilter.controls.size.setValue(size);
    this.getData();
    this.getTotals();
  }

  changeDataTable(props) {
    console.log('props', props);
    if (props.type && props.type === "download") {
      console.log(props.data.mcode);
      this.downloadData(props.data.mcode);
      return;
    }
    this.formFilter.controls.from_date.setValue(props.from_date);
    this.formFilter.controls.to_date.setValue(props.to_date);
    this.defaultMerchantCode = props.mcode;
    this.getData(props.mcode);
    this.getTotals(props.mcode);
  }
}
