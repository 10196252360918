import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ITotaltable } from '@interfaces/ITotaltable';

@Component({
    selector: 'app-tool-bar-footer',
    templateUrl: './tool-bar-footer.component.html',
    styleUrls: ['./tool-bar-footer.component.scss']
  })

export class ToolBarFooterComponent implements OnInit, OnChanges {
    @Input() totalTable: ITotaltable;

    public totalCOFormat = '';

    public currencyCOP = false;

    ngOnInit(): void {
      this.updateData();
    }

    ngOnChanges(changes: SimpleChanges): void {
      this.updateData();
    }

    private updateData(): void {
      this.currencyCOP = false;
      setInterval(() => {
        if (this.totalTable.currency === 'COP') {
          this.currencyCOP = true;
        }
      }, 1000);
      let formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0,
      });
      this.totalCOFormat = formatter.format(this.totalTable.totalCOP);


      this.totalCOFormat = '(COP)' + this.totalCOFormat;
    }

}
