import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { MessageErrorService } from "./../../../../../services/utils/message-error.service";
import { BalanceService } from "./../../../../../services/balance/balance.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { ProviderService } from "@services/provider/provider.service";

@Component({
  selector: "app-provider-detail",
  templateUrl: "./provider-detail.component.html",
  styleUrls: ["./provider-detail.component.scss"],
})
export class ProviderDetailComponent implements OnInit {
  public details: any = this.processDetail();
  public historical: any = this.processHistorical();
  public titlePage: any;
  modalDevolucion: boolean = false;
  formReverseBalance: FormGroup;
  titleModal: string = "";
  contentModal: any = "";
  okText: any = "";
  dataEventModal: any;
  ProviderBalanceActivityID: string;
  messageModal: string;
  dataReverse: any;

  constructor(
    private aR: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private balanceService: BalanceService,
    private messageError: MessageErrorService,
    private notification: NzNotificationService,
    private providerService: ProviderService
  ) { }

  ngOnInit() {
    this.formReverseBalance = this.createFormReverse();
  }

  createFormReverse() {
    return new FormGroup({
      ProviderCode: new FormControl(""),
      LegalName: new FormControl(""),
      BankCode: new FormControl(""),
      Reference: new FormControl("", [Validators.required]),
      Amount: new FormControl(""),
      CurrencyId: new FormControl("COP"),
      description: new FormControl("", [Validators.required]),
    });
  }

  async processDetail() {
    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split("-")[0];
    this.titlePage = "Proveedor > Movimientos entrada > " + id;

    return {
      headers: this.getHeadersDetails(),
      data: this.getDetails(id, "provider/detail"),
    };
  }

  async getDetails(id, service) {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: service,
      data: id,
    };
    const response = await this.api.api(data);
    return response.toPromise();
  }

  getHeadersDetails() {
    return [
      {
        name: "Proveedor",
        key: "Balance-BussinesName",
      },
      {
        name: "Saldo Actual",
        key: "Balance-CurrentBalance",
      },
      {
        name: "Saldo Minimo",
        key: "Balance-MinAmount",
      },
      {
        name: "Currency",
        key: "Balance-CurrencyCode",
      },
      {
        name: "Bolsa Activa",
        key: "Balance-BagType",
      },
    ];
  }

  getHeadersHistorical() {
    return [
      {
        name: "Fecha de registro",
        key: "created_at",
      },
      {
        name: "Total recarga",
        key: "Amount",
      },
      {
        name: "Moneda",
        key: "CurrencyCode",
      },
      {
        name: "Banco",
        key: "ShortName",
      },
      {
        name: "Descripció n",
        key: "Description",
      },
      // {
      //   name: "Acciones",
      //   key: "actions",
      // },
    ];
  }

  async processHistorical() {
    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split("-")[0];

    return await this.getDetails(id, "provider/detail")
      .then((data: any) => {
        const activity = [];
        data.Historico.forEach((e) => {
          e.Amount = new Intl.NumberFormat("en-US").format(e.Amount);
          activity.push(e);
        });

        return {
          headers: this.getHeadersHistorical(),
          data: activity,
          title: "Historico:",
        };
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
        }
      });
  }

  eventsOutput(args: any) {
    switch (args.function) {
      case "reverse":
        this.titleModal = "Retraer Saldo";
        this.contentModal = "newBalanceReverse";
        this.dataEventModal = "newBalanceReverse";
        this.modalDevolucion = true;
        this.dataReverse = args.data;
        console.log("this.dataReverse", this.dataReverse);
        this.ProviderBalanceActivityID =
          this.dataReverse.ProviderBalanceActivityID;
        this.formReverseBalance.get("Amount").setValue(this.dataReverse.Amount);
        this.formReverseBalance
          .get("Reference")
          .setValue(this.dataReverse.Reference);
        this.formReverseBalance
          .get("BankCode")
          .setValue(this.dataReverse.BankCode);
        this.formReverseBalance
          .get("ProviderCode")
          .setValue(this.dataReverse.provider.ProviderCode);
        this.formReverseBalance
          .get("LegalName")
          .setValue(this.dataReverse.provider.LegalName);
        this.formReverseBalance
          .get("CurrencyId")
          .setValue(this.dataReverse.CurrencyCode);
        break;

      default:
        break;
    }
  }

  handleCancel(): void {
    this.modalDevolucion = false;
    this.formReverseBalance.reset();
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case "newBalanceReverse":
        if (!this.formReverseBalance.valid) {
          this.messageError.showValidationMsg(this.formReverseBalance);
          break;
        }
        this.balanceService
          .cashinReverse(
            this.ProviderBalanceActivityID,
            this.formReverseBalance.value
          )
          .subscribe(
            (response: any) => {
              this.notification.create("success", response.Message, "");
              this.handleCancel();
            },
            (error) => {
              this.notification.create(
                "error",
                "Ha ocurrido un error",
                error.error.Message
              );
            }
          );
        break;
      default:
        break;
    }
  }
}
