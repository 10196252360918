import { ITotaltable } from '@interfaces/ITotaltable';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.scss']
})
export class CashoutComponent implements OnInit {

  public allOperationsCashOutData: any = this.allOperationsCashOut();
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Cash Out';
  public totaltable: ITotaltable = {
    countRow : 0,
    currency: '',
    totalCOP: 0,
  };

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async allOperationsCashOut(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'cashout/operations',
      data: []
    };

    const headers = [
      {
        'name': 'Operación',
        'key': 'PublicID',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'Customer-Info-FullName',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'Customer-InfoBank-CustomerBank',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Fecha de depósito',
        'key': 'PaymementDate',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      }
    ];

    if (dataInfo !== false) {
      data.service = 'listAllOperationCashOutWithParams';
      data.data = dataInfo;
    }
/*                                                                                   */
    const getData = await this.api.api(data).toPromise() as any[];
    for (var i in getData) {
      getData[i].Amount = new Intl.NumberFormat("en-US").format(getData[i].Amount);
    } 
    
    this.totaltable = {
      countRow : 0,
      currency: '',
      totalCOP: 0,
    };
    getData.forEach((item, i) => {
      let laguage = 'es-CO';
      
      if (item.CurrencyCode === 'COP') {
        this.totaltable.totalCOP += Number(String(item.Amount).replace(',', '.'));
      }
      const formatter = new Intl.NumberFormat(laguage, {
        style: 'decimal',
        currency: item.CurrencyCode,
        minimumFractionDigits: 2,
      });
      getData[i].Amount = formatter.format(Number(String(item.Amount).replace(',', '.')));
      // result[i].Amount = Number(9000000.01).toLocaleString('es', {maximumFractionDigits: 0,  minimumFractionDigits: 0});
    });
    this.totaltable.currency = 'COP';
    this.totaltable.countRow = getData.length;

/*                                                                                   */

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'operation-cashout'
    };

  }



  async getFilters() {

    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    }

    let dataStatus = this.api.getStatus();


    let dataMerchant = [];
    let dataBanks = [];

    await this.api.api({
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'listBanksCashout'
    }).toPromise().then((result: any) => {

      result.forEach(element => {
        dataBanks.push({
          name: element.ShortName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if(error.status == 401){
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });


    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Cliente',
        'key': 'FullName',
        'type': 'text'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'Banco',
        'key': 'bankCode',
        'type': 'select',
        'data': dataBanks
      },
      {
        'name': 'N° Operación',
        'key': 'publicId',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date','to_date'],
        'type': 'period'
      }

    ];
  }

  changeDataTable(data) {
    this.allOperationsCashOutData = this.allOperationsCashOut(data);
  }

}
