
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { HistoricalDetail, HistoricalInvoice } from './models/historical-detail';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {
  @Input() dataPdf : any
  @ViewChild('download', null) read: ElementRef

  id: string
  data: any
  response: string
  columns: Array<any> = []
  currentPdf: any

  constructor(private aR: ActivatedRoute, private api: ApiService, private router: Router) { 
    this.id = this.aR.snapshot.paramMap.get('id')
    this.donwloadPDF()
    console.log('PDF_FORMAT: ', this.id)
    
  }

  ngOnInit(): void {
    this.getData() 
    // this.api.isLoad()
    console.log( 'currentPdf: ', this.dataPdf)

  }
  // ngOnChanges(): void{
  //   console.log( 'currentPdf: ', this.dataPdf)
  // }



getData(){

}
donwloadPDF() {

  var data = document.getElementById('download');
  html2canvas(data).then(canvas => {
    // Few necessary setting options  
    var imgWidth = 180;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    var date = new Date();
    const imgData = canvas.toDataURL('image/png')

    var doc = new jsPDF('p', 'mm');
    var position = 10;

    doc.addImage(imgData, 'PNG', 12, position, imgWidth, imgHeight + 15);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
      heightLeft -= pageHeight;
    }

    // let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    // var position = 0;
    // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    doc.save('export' + date.getTime() + '.pdf'); // Generated PDF
  });
 }
}