import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.css']
})
export class UserMaintenanceComponent implements OnInit {

  formAdministrator: FormGroup;
  formMerchant: FormGroup;

  public dataAdminInfo: any = this.getDataInfo('user/admin');
  public dataMerchantInfo: any = this.getDataInfo('user/merchant');

  isAdministradorSelected = true;
  isMerchantSelected = false;

  isCreateAdministradorModalVisible = false;
  isCreatMerchantModalVisible = false;

  isEditAdministradorModalVisible = false;
  isEditMerchantModalVisible = false;

  isResetPasswordModalVisible = false;

  constructor(private api: ApiService, private router: Router) {
    this.formAdministrator = this.createFormAdministratorFormGroup();
    this.formMerchant = this.createFromMerchantFromGroup();
   }

  ngOnInit() {
  }

  createFormAdministratorFormGroup() {
    return new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      phone: new FormControl(''),
      profile: new FormControl('1000'),
      state: new FormControl('1')
    });
  }

  createFromMerchantFromGroup() {
    return new FormGroup({
      merchantName: new FormControl(''),
      merchantEmail: new FormControl(''),
      merchantPassword: new FormControl(''),
      merchantPhone: new FormControl(''),
      merchantProfile: new FormControl('1000'),
      merchantState: new FormControl('1'),
      merchantList: new FormControl('')
    });
  }

  async getDataInfo(dataType: string) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: dataType,
      data: []
    };

    const headers = [
      {
        'name': 'Name',
        'key': 'fullname',
        'opc': true
      },
      {
        'name': 'Email',
        'key': 'email',
        'opc': true
      },
      {
        'name': 'Phone',
        'key': 'mobile',
        'opc': true
      },
      {
        'name': 'Profile',
        'key': 'profile',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit', 'Reset Password']
      }
    ];

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
    };
  }

  selectAdministradorOption() {
    this.resetOptions();
    this.isAdministradorSelected = true;
  }

  selectMerchantOption() {
    this.resetOptions();
    this.isMerchantSelected = true;
  }

  resetOptions() {
    this.isAdministradorSelected = false;
    this.isMerchantSelected = false;
  }

  newItem() {
    if (this.isAdministradorSelected) {
      this.isCreateAdministradorModalVisible = true;
    } else {
      this.isCreatMerchantModalVisible = true;
    }
  }

  cancelCreateModal() {
    this.isCreateAdministradorModalVisible = false;
    this.isCreatMerchantModalVisible = false;
    this.formAdministrator = this.createFormAdministratorFormGroup();
    this.formMerchant = this.createFromMerchantFromGroup();
  }

  saveCreateModal() {
    if (this.isAdministradorSelected) {
      this.createUser(1).then((result: any) => {
        // REFRESH DATA
        this.dataAdminInfo = this.getDataInfo('user/admin');
        this.isCreateAdministradorModalVisible = false;
        this.formAdministrator = this.createFormAdministratorFormGroup();
      }).catch(error => {
        console.log('ERROR', error);
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
    } else {

      this.createUser(2).then((result: any) => {
        // REFRESH DATA
        this.dataMerchantInfo = this.getDataInfo('user/merchant');
        this.isCreatMerchantModalVisible = false;
        this.formMerchant = this.createFromMerchantFromGroup();
      }).catch(error => {
        console.log('ERROR', error);
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
    }
  }

  async createUser(type) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'User',
      data: {
        email: (type === 1) ? this.email.value : this.merchantEmail.value,
        fullname: (type === 1) ? this.name.value : this.merchantName.value,
        mobile : (type === 1) ? this.phone.value : this.merchantPhone.value,
        password: (type === 1) ? this.password.value : this.merchantPassword.value,
        type: type,
        profileId: (type === 1) ? this.profile.value : this.merchantProfile.value,
        listMerchant: (type === 1) ? '' : this.merchantList.value,
        metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails)
      }
    };
    return await this.api.api(data).toPromise();
  }

  cancelEditModal() {
    this.isEditAdministradorModalVisible = false;
    this.isEditMerchantModalVisible = false;
  }

  saveEditModal() {
    this.isEditAdministradorModalVisible = false;
    this.isEditMerchantModalVisible = false;
    // CALL SERVICES
    // RESET VALUES
  }

  handleOpcionEvents(list) {
    if (list.function === 'Edit') {
      if (this.isAdministradorSelected) {
        //  SHOW EDIT ADMIN MODAL
        this.isEditAdministradorModalVisible = true;
        //  CALL DETAIL ADMIN USER SERVICE - TODO
        //  RESET FIELDS - TODO
      } else {
        //  SHOW EDIT MERCHANT MODAL
        this.isEditMerchantModalVisible = true;
        //  CALL DETAIL MERCHANT USER SERVICE - TODO
        //  RESET FIELDS - TODO
      }
    } else {
      // CALL REST PASSWORD SERVICE
      this.isResetPasswordModalVisible = true;
    }
  }

  closeResetPasswordModal() {
    this.isResetPasswordModalVisible = false;
  }

  saveResetPasswordModal() {
    // TODO CALL CONFIRM SERVICE
    this.isResetPasswordModalVisible = false;
  }

  get name() { return this.formAdministrator.get('name'); }
  get email() { return this.formAdministrator.get('email'); }
  get password() { return this.formAdministrator.get('password'); }
  get phone() { return this.formAdministrator.get('phone'); }
  get profile() { return this.formAdministrator.get('profile'); }
  get state() { return this.formAdministrator.get('state'); }

  get merchantName() { return this.formMerchant.get('merchantName'); }
  get merchantEmail() { return this.formMerchant.get('merchantEmail'); }
  get merchantPassword() { return this.formMerchant.get('merchantPassword'); }
  get merchantPhone() { return this.formMerchant.get('merchantPhone'); }
  get merchantProfile() { return this.formMerchant.get('merchantProfile'); }
  get merchantState() { return this.formMerchant.get('merchantState'); }
  get merchantList() { return this.formMerchant.get('merchantList'); }

}
