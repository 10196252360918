import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-payroll-detail',
  templateUrl: './payroll-detail.component.html',
  styleUrls: ['./payroll-detail.component.css']
})
export class PayrollDetailComponent implements OnInit {

  public details: any = this.processDetail();
  public historical: any = this.processHistorical();
  public titlePage: any;

  constructor(private aR: ActivatedRoute, private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async processDetail() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];
    this.titlePage = 'Operation > Cash Out > Nóminas > ' + id;

    return {
      headers: this.getHeadersDetails(),
      data: this.getDetails(id, 'payroll/detail')
    };
  }


  async getDetails(id, service) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }


  getHeadersDetails() {
  return [
    {
      name: 'ID',
      key: 'Information-PublicID'
    },
    {
      name: 'Templete',
      key: 'Information-Templete'
    },
    {
      name: 'Total Record',
      key: 'Information-TotalRecord'
    },
    {
      name: 'Total Amount',
      key: 'Information-TotalAmount'
    },
    {
      name: 'Currency',
      key: 'Information-CurrencyCode'
    },
    {
      name: 'Date',
      key: 'Information-created_at'
    },
    {
      name: 'Last Status',
      key: 'Information-LastStatus'
    },
  ];
}


  // HISTORICAL

  getHeadersHistorical() {

    return [
      {
        name: 'ID',
        key: 'PublicID'
      },
      {
        name: 'Reference',
        key: 'MerchantReference'
      },
      {
        name: 'Date',
        key: 'TxCreation'
      },
      {
        name: 'Amount',
        key: 'Amount'
      },
      {
        name: 'Currency',
        key: 'CurrencyCode'
      }
    ];
  }

  async processHistorical() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];

    return await this.getDetails(id, 'payroll/detail').then((data: any) => {

      const activity = [];
      data.listTransactions.forEach(e => {
        // e.Status = this.api.getStatusByID(e.Status.toString());
        activity.push(e);
      });

      return {
          headers: this.getHeadersHistorical(),
          data: activity,
          title: 'Transacciones:'
      };
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }
}
