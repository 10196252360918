import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-commission-card',
  templateUrl: './commission-card.component.html',
  styleUrls: ['./commission-card.component.css']
})
export class CommissionCardComponent implements OnInit {

  @Input() data: any;
  @Input() title: string;
  @Input() isPENVisible?: boolean = false;
  @Input() isUSDVisible?: boolean = false;


  constructor() { }

  ngOnInit() {
    console.log(this.data);
  }

}
