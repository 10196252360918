import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private api: ApiService, private router: Router) { }

  async getDataCountry() {
    const data = {
      service: 'countries'
    };

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.CountryName,
          value: element.CountryCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    return dataMerchant;
  }
}
