import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserData } from "../settings/interfaces/response-login";

const API_ACCES_URL = environment.urlAcces;

export interface ResponseValidateUser {
	statusCode: number;
	status: boolean;
	message: string;
	data: boolean;
	metadata: any;
}

@Injectable({
    providedIn: 'root'
})
export class AccessService {
    private user: UserData = sessionStorage.getItem('ud') ? JSON.parse(sessionStorage.getItem('ud')) : null;

    constructor(private http: HttpClient) {
    }

    get tokenAccess(): string {
        return localStorage.getItem('authAccess') != null ? JSON.parse(localStorage.getItem('authAccess')).data.access_token : '';
    }

    login(data: { email: string, password: string }) {
        const url = `${API_ACCES_URL}login`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    isUseTokenGoogle(){
        const url = `${API_ACCES_URL}google/validate/register`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res;
            }));
    }

    isUseTokenWithUserIdGoogle(userId: string ){ 
        const url = `${API_ACCES_URL}google/validate/register/` + userId;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getQrWithUseridGoogle(userId: string, isReset: boolean){
        const url = `${API_ACCES_URL}google/qr/user`;
        return this.http.post(url, {userId, isReset}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getQrGoogle(isReset: boolean){
        const url = `${API_ACCES_URL}google/qr`;
        return this.http.post(url, {isReset}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    validateAuthToken(token: string): Observable<ResponseValidateUser> {
		return this.http
			.post<ResponseValidateUser>(
				`${API_ACCES_URL}google/validate`,
				{ userId: this.user.userDetails.id, twoFactorAuthenticationToken: token },
			)
	}
}
