import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NzBreadCrumbComponent } from 'ng-zorro-antd';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-commerce-detail',
  templateUrl: './commerce-detail.component.html',
  styleUrls: ['./commerce-detail.component.css']
})
export class CommerceDetailComponent implements OnInit {

  formForcePayment: FormGroup;
  formSettings: FormGroup;
  formNotifications: FormGroup;
  formStaggered: FormGroup;
  formBankSection: FormGroup;

  isloading = true;
  isDetailVisible = false;
  isInitialDetailData  = false;
  istCountry: any;
  public data: any = this.getDataDetail();
  public filter: any = this.getDataCountry();
  listCountry: any;
  view: any;

  isDataSelected = true;
  isSettingSelected = false;
  isNotificationsSelected = false;
  isCommissionsSelected = false;
  isBankSelected = false;


  isCashInSettingSelected = true;
  isCashOutSettingSelected = false;
  isCheckboxCashInSettingSelected = false;
  isCheckboxCashOutSettingSelected = false;


  selectedCommissionFilterType = 'cashIn';
  selectedCashInCommissionFilterType = '';
  selectedCashOutCommissionFilterType = '';

  settingCashOutMaxamountSolesInitial = '';
  settingCashOutMaxamountDolaresInitial = '';
  settingCashOutMinamountSolesInitial = '';
  settingCashOutMinamountDolaresInitial = '';


  settingChannelCashInOnline = false;
  settingChannelCashInCash = false;
  settingChannelCashInTimeOnline = '';
  settingChannelCashInTimeCash = '';

  settingCashInCurrencySoles = false;
  settingCashInCurrencyDolares = false;
  settingCashOutCurrencySoles = false;
  settingCashOutCurrencyDolares = false;

  cashoutData: any;
  cashoutDataPercentage: any;
  commissionsCashInChannelCash: any;
  commissionsCashInChannelOnline: any;
  commissionsCashInPercentage: any;
  commissionsCashInCurrencySoles: any;
  commissionsCashInCurrencyDolares: any;
  commissionsCashInstaggered: any;
  commissionsCashInbank: any;

  isStaggeredCreateModalVisible = false;
  isStaggereEditdModalVisible = false;

  staggeredEditListData = '';
  staggeredEditFrom = '';
  staggeredEditUntil = '';
  staggeredEditPercentage = '';
  staggeredEditMinimum = '';
  staggeredEditMaximum = '';
  staggeredEditIVA = '';

  bankData: any;
  bankSectionEditListData = '';
  isBankSectionCreateModalVisible = false;
  isBankSectionEditdModalVisible = false;

  constructor(private aR: ActivatedRoute, private api: ApiService, private router: Router) {
    this.formForcePayment = this.createFormGroup();
    this.formSettings = this.createFromSettingGroup();
    this.formNotifications = this.createFromNotificationsGroup();
    this.formStaggered = this.createFromComissionStaggered();
    this.formBankSection = this.createFormBankSectionGroup();
    const id = this.aR.snapshot.paramMap.get('id');
    this.view = this.aR.snapshot.paramMap.get('view');

    if (id && this.view) {
      // this.data = this.getDataDetail();
      this.isDetailVisible = true;
    } else {
      this.isDetailVisible = false;
    }

  }

  createFormBankSectionGroup() {
    return new FormGroup({
      bankType: new FormControl(''),
      bankBank: new FormControl(''),
      bankAccountNumber: new FormControl(''),
      bankCurrency: new FormControl(''),
      bankState: new FormControl('')
    });
  }

  createFromComissionStaggered() {
    return new FormGroup({
      staggeredFrom: new FormControl(''),
      staggeredUntil: new FormControl(''),
      staggeredPercentage: new FormControl(''),
      staggeredMinimum: new FormControl(''),
      staggeredMaximum: new FormControl(''),
      staggeredIVA: new FormControl('')
    });
  }

  createFormGroup() {
    return new FormGroup({
      legal_name: new FormControl(''),
      country_code: new FormControl('PER'),
      bussines_name: new FormControl(''),
      legal_number: new FormControl(''),
      url_web: new FormControl(''),
      merchant_type: new FormControl('1'),
      color_1: new FormControl(''),
      color_2: new FormControl(''),
      color_3: new FormControl(''),
      user_fullname: new FormControl(''),
      user_email: new FormControl(''),
      user_mobile: new FormControl('')
    });
  }

  createFromNotificationsGroup() {
    return new FormGroup({
      activeNotificationWebhookCashIn: new FormControl(false),
      notificationURLCashIn: new FormControl(''),
      activeNotificationEmailCashIn: new FormControl(false),
      listEmailsCashIn: new FormControl(''),
      activeSmsShopperCashIn: new FormControl(false),
      activeEmailShopperCashIn: new FormControl(false),

      activeNotificationWebhookCashOut: new FormControl(false),
      notificationURLCashOut: new FormControl(''),
      activeNotificationEmailCashOut: new FormControl(false),
      listEmailsCashOut: new FormControl(''),
      activeSmsShopperCashOut: new FormControl(false),
      activeEmailShopperCashOut: new FormControl(false)
    });
  }

  createFromSettingGroup() {
    return new FormGroup({
      active_cash_in: new FormControl(false),
      active_cash_out: new FormControl(false),
      setting_cash_in_check: new FormControl(false),
      setting_cash_out_check: new FormControl(false),
      setting_cash_in_time: new FormControl(''),
      setting_cash_out_time: new FormControl(''),
      setting_cash_in_currency_soles: new FormControl(''),
      setting_cash_in_currency_dolares: new FormControl(''),
      setting_cash_out_currency_soles: new FormControl(''),
      setting_cash_out_currency_dolares: new FormControl(''),
      setting_cash_out_maxAmount_soles: new FormControl(''),
      setting_cash_out_minAmount_soles: new FormControl(''),
      setting_cash_out_maxAmount_dolares: new FormControl(''),
      setting_cash_out_minAmount_dolares: new FormControl('')
    });
  }

  ngOnInit() {
  }

  async getDataCountry() {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'countries'
    };

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.CountryName,
          value: element.CountryCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    this.listCountry = dataMerchant;
    return dataMerchant;
  }

  async getDataDetail() {
    const id = this.aR.snapshot.paramMap.get('id');
    this.view = this.aR.snapshot.paramMap.get('view');

    if (id && this.view) {
      // this.data = this.getDataDetail();
      this.isDetailVisible = true;
    } else {
      this.isDetailVisible = false;
    }

    if (id !== null) {

    this.isInitialDetailData = false;
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchant/detail',
      data: id
    };

    return await this.getDetails(id, 'merchant/detail').then((result: any) => {
      this.data = result;
      this.isCheckboxCashInSettingSelected = this.data.ServiceActive.ActiveCashin;
      this.isCheckboxCashOutSettingSelected = this.data.ServiceActive.ActiveCashout;
      this.selectedCashInCommissionFilterType = this.data.Commissions.Cashin.Type;
      this.selectedCashOutCommissionFilterType = this.data.Commissions.Cashout.Type;

      if (this.data.Settings.Cashout) {
        if (this.data.Settings.Cashout.ListCurrencies) {
          this.settingCashOutMaxamountSolesInitial =
            (this.data.Settings.Cashout.ListCurrencies[0]) ? this.data.Settings.Cashout.ListCurrencies[0].MaxAmount : null;
          this.settingCashOutMaxamountDolaresInitial =
            (this.data.Settings.Cashout.ListCurrencies[1]) ? this.data.Settings.Cashout.ListCurrencies[1].MaxAmount : null;
          this.settingCashOutMinamountSolesInitial =
            (this.data.Settings.Cashout.ListCurrencies[0]) ? this.data.Settings.Cashout.ListCurrencies[0].MinAmount : null;
          this.settingCashOutMinamountDolaresInitial =
            (this.data.Settings.Cashout.ListCurrencies[1]) ? this.data.Settings.Cashout.ListCurrencies[1].MinAmount : null;
        }
      }

      if (this.data.Settings.Cashin) {
        if (this.data.Settings.Cashin.Channel) {
          this.settingChannelCashInOnline =
            (this.data.Settings.Cashin.Channel[0]) ? this.data.Settings.Cashin.Channel[0].Active : null;
          this.settingChannelCashInCash =
            (this.data.Settings.Cashin.Channel[1]) ? this.data.Settings.Cashin.Channel[1].Active : null;
          this.settingChannelCashInTimeOnline =
            ( this.data.Settings.Cashin.Channel[0]) ?  this.data.Settings.Cashin.Channel[0].ExpirationTime : null;
          this.settingChannelCashInTimeCash =
            ( this.data.Settings.Cashin.Channel[1]) ?  this.data.Settings.Cashin.Channel[1].ExpirationTime : null;
        }
      }

      if (this.data.Settings.Cashin) {
        if (this.data.Settings.Cashin.ListCurrencies) {
          this.settingCashInCurrencySoles =
            (this.data.Settings.Cashin.ListCurrencies[0]) ? this.data.Settings.Cashin.ListCurrencies[0].Active : null;
          this.settingCashInCurrencyDolares =
            (this.data.Settings.Cashin.ListCurrencies[1]) ? this.data.Settings.Cashin.ListCurrencies[1].Active : null;
        }
      }

      if (this.data.Settings.Cashout) {
        if (this.data.Settings.Cashout.ListCurrencies) {
          this.settingCashOutCurrencySoles =
            (this.data.Settings.Cashout.ListCurrencies[0]) ? this.data.Settings.Cashout.ListCurrencies[0].Status : null;
          this.settingCashOutCurrencyDolares =
            (this.data.Settings.Cashout.ListCurrencies[1]) ? this.data.Settings.Cashout.ListCurrencies[1].Status : null;
        }
     }

      this.isloading = false;

      this.validateSelectedCommissionFilterType();
      this.handleDataCommissionsTable();
    });
  } else {
    this.isloading = false;
    this.isInitialDetailData = true;
  }
  }

  handleDataCommissionsTable() {
    if (this.selectedCashInCommissionFilterType === 'CI_001') {
      this.commissionsCashInPercentage = this.getCashInData();
    }
    if (this.selectedCashInCommissionFilterType === 'CI_002') {
      this.commissionsCashInChannelCash = this.getCashInDataCash();
      this.commissionsCashInChannelOnline = this.getCashInDataOnline();
    }
    if (this.selectedCashInCommissionFilterType === 'CI_003') {
      this.commissionsCashInCurrencySoles = this.getCashInDataCash();
      this.commissionsCashInCurrencyDolares = this.getCashInDataOnline();
    }
    if (this.selectedCashInCommissionFilterType === 'CI_004') {
      this.commissionsCashInstaggered = this.getCashInData004();
    }
    if (this.selectedCashInCommissionFilterType === 'CI_005') {
      this.commissionsCashInbank = this.getCashInData004();
    }
    if (this.selectedCashOutCommissionFilterType === 'CO_001') {
      this.cashoutDataPercentage = this.getCashOutData();
    }
    if (this.selectedCashOutCommissionFilterType === 'CO_002') {
      this.cashoutData = this.getCashOutData002();
    }
  }

  getCashInDataCash() {
    return this.data.Commissions.Cashin.Data[0];
  }

  getCashInDataOnline() {
    return this.data.Commissions.Cashin.Data[1];
  }

  getCashInData() {
    return this.data.Commissions.Cashin.Data[0];
  }

  getCashOutData() {
    return this.data.Commissions.Cashout.Data[0];
  }

  async getCashOutData002() {
    const headers = [
      {
        'name': 'Minimum',
        'key': 'Minimum',
        'opc': true
      },
      {
        'name': 'Maximum',
        'key': 'Maximum',
        'opc': true
      },
      {
        'name': 'IVA',
        'key': 'Tax',
        'opc': true
      },
      {
        'name': 'Fee',
        'key': 'Fee',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit'],
      }
    ];

    return {
      headers: headers,
      data:  await this.failPromise()
    };
  }

  async getCashInData004() {
    const headers = [
      {
        'name': 'Minimum',
        'key': 'Minimum',
        'opc': true
      },
      {
        'name': 'Maximum',
        'key': 'Maximum',
        'opc': true
      },
      {
        'name': 'IVA',
        'key': 'Tax',
        'opc': true
      },
      {
        'name': 'Fee',
        'key': 'Fee',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit'],
      }
    ];

    return {
      headers: headers,
      data:  await this.failPromiseCashIn()
    };
  }

  failPromise() {
    return this.data.Commissions.Cashout.Data;
  }

  failPromiseCashIn() {
    return this.data.Commissions.Cashin.Data;
  }

  showCreateStaggeredModal() {
    this.isStaggeredCreateModalVisible = true;
  }

  handleCancelStaggeredItem() {
    this.createFromComissionStaggered();
    this.isStaggeredCreateModalVisible = false;
  }

  handleCreateStaggeredItem() {
    // TODO - call service - use the formControlName to get inputs
    this.createFromComissionStaggered();
    this.isStaggeredCreateModalVisible = false;
  }

  handleOpcionEvents(list) {
    this.staggeredEditListData = list.data;
    if (list.function === 'Edit') {
      this.isStaggereEditdModalVisible = true;
    }
  }

  showCreateBankSectionModal() {
    this.isBankSectionCreateModalVisible = true;
  }

  handleCancelBankSectionItem() {
    this.isBankSectionCreateModalVisible = false;
  }

  handleCreateBankSectionItem() {
    // use states  (create) to get inputs
    this.createFormBankSectionGroup();
    this.isBankSectionCreateModalVisible = false;
  }

  handleBankSectionEvents(list) {
    this.bankSectionEditListData = list.data;
    if (list.function === 'Edit') {
      this.isBankSectionEditdModalVisible = true;
    }
  }

  handleCancelUpdateBankSectionItem() {
    this.isBankSectionEditdModalVisible = false;
  }

  handleUpdateBankSectionItem() {
    // use states  (create) to get inputs
    this.createFormBankSectionGroup();
    this.isBankSectionEditdModalVisible = false;
  }

  handleCancelUpdateStaggeredItem() {
    this.cleanEditStaggeredItems();
    this.isStaggereEditdModalVisible = false;
  }

  cleanEditStaggeredItems() {
    this.staggeredEditFrom = '';
    this.staggeredEditUntil = '';
    this.staggeredEditPercentage = '';
    this.staggeredEditMinimum = '';
    this.staggeredEditMaximum = '';
    this.staggeredEditIVA = '';
  }

  handleUpdateStaggeredItem() {
    // use the this.staggeredEditListData states to get inputs
    // TODO - call service
    this.cleanEditStaggeredItems();
    this.isStaggereEditdModalVisible = false;
  }

  validateSelectedCommissionFilterType() {
    if (this.isCheckboxCashInSettingSelected) {
      this.selectedCommissionFilterType = 'cashIn';
    } else if (this.isCheckboxCashOutSettingSelected) {
      this.selectedCommissionFilterType = 'cashOut';
    } else {
      this.selectedCommissionFilterType = '';
    }
  }

  async getDetails(id, service) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }

  handleCreateData(): void {
    this.createNewSetting().then((result: any) => {
      this.isDetailVisible = true;
      this.isInitialDetailData = false;
      this.selectSettingOption();
      // ver aca el result que retona el crete new item
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  async createNewSetting() {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchant',
      data: {
        legal_name: this.legal_name.value,
        country_code: this.country_code.value,
        bussines_name: this.bussines_name.value,
        legal_number: this.legal_number.value,
        url_web: this.url_web.value,
        merchant_type: this.merchant_type.value,
        color_1: this.color_1.value,
        color_2: this.color_2.value,
        color_3: this.color_3.value,
        user_fullname: this.user_fullname.value,
        user_email: this.user_email.value,
        user_mobile: this.user_mobile.value
      }
    };
    return await this.api.api(data).toPromise();
  }

  handleActivation(typeNumber): void {
    this.createNewAction(typeNumber).then((result: any) => {
      if (typeNumber === 1) {
        this.isCheckboxCashInSettingSelected = !this.isCheckboxCashInSettingSelected;
      } else {
        this.isCheckboxCashOutSettingSelected = !this.isCheckboxCashOutSettingSelected;
      }
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  async createNewAction(numberType: any) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchant/activation_proccess',
      data: {
        type: numberType,
        status: (numberType === 1) ? this.isCheckboxCashInSettingSelected : this.isCheckboxCashOutSettingSelected
      },
      id: numberType
    };
    return await this.api.api(data).toPromise();
  }

  selectDataOption() {
    this.resetOptions();
    this.isDataSelected = true;
  }

  selectSettingOption() {
    this.resetOptions();
    this.isSettingSelected = true;
  }

  selectNotificationOption() {
    this.resetOptions();
    this.isNotificationsSelected = true;
  }

  selectComissionOption() {
    this.resetOptions();
    this.isCommissionsSelected = true;
    this.bankData = this.getBankSectionValues();
  }

  selectBankOption() {
    this.resetOptions();
    this.isBankSelected = true;
    this.getBankSectionValues();
  }

  async getBankSectionValues() {

      const headers = [
        {
          'name': 'Type',
          'key': 'Type',
          'opc': true
        },
        {
          'name': 'Bank',
          'key': 'ShortName',
          'opc': true
        },
        {
          'name': 'Account Number',
          'key': 'NumberAccount',
          'opc': true
        },
        {
          'name': 'Currency',
          'key': 'CurrencyCode',
          'opc': true
        },
        {
          'name': 'State',
          'key': 'Status',
          'opc': true
        },
        {
          'name': 'Opciones',
          'key': 'standard-list',
          'opc': false,
          'functions': ['Edit'],
        }
      ];

      return {
        headers: headers,
        data:  await this.failBankInformation()
      };
  }

  failBankInformation() {
    return this.data.BankAccount;
  }

  resetOptions() {
    this.isDataSelected = false;
    this.isSettingSelected = false;
    this.isNotificationsSelected = false;
    this.isCommissionsSelected = false;
    this.isBankSelected = false;
  }

  setCollapsibleSection(selectedSection) {
    this.isCashInSettingSelected = !this.isCashInSettingSelected;
    this.isCashOutSettingSelected = !this.isCashOutSettingSelected;
  }

  updateCommissionFilterType(event) {
    if (event.target.value  === 'cashIn') {
      this.selectedCommissionFilterType = 'cashIn';
      if (!this.data.Commissions.Cashin.Type) {
        this.selectedCashInCommissionFilterType = 'CI_001';
      } else {
        this.selectedCashInCommissionFilterType = this.data.Commissions.Cashin.Type;
      }
    } else {
      this.selectedCommissionFilterType = 'cashOut';
      if (!this.data.Commissions.Cashout.Type) {
        this.selectedCashOutCommissionFilterType = 'CO_001';
      } else {
        this.selectedCashOutCommissionFilterType = this.data.Commissions.Cashout.Type;
      }
    }
  }

  updateCashInCommissionFilterType(event) {
    this.selectedCashInCommissionFilterType = event.target.value;
  }

  updateCashOutCommissionFilterType(event) {
    this.selectedCashOutCommissionFilterType = event.target.value;
  }

  toogleSettingCashIn() {
    if (this.data.Settings) {
      this.isCheckboxCashInSettingSelected = !this.isCheckboxCashInSettingSelected;
    } else {
      this.handleActivation(1);
      // 'merchant/activation_proccess'
    }
  }

  toogleSettingCashOut() {
    // validate response aca!!
    if (this.data.Settings) {
      this.isCheckboxCashOutSettingSelected = !this.isCheckboxCashOutSettingSelected;
    } else {
      this.handleActivation(2);
      // 'merchant/activation_proccess'
    }
  }

  handleNotificacionsSection() {
    // service pending
  }

  handleCommissionSection() {
    // service pending
  }

  async updateChannelSwitch(item) {

    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchant_channel',
      id: this.aR.snapshot.paramMap.get('id'),
      data: {
        type: 1,
        channel_name: item,
        status: (item) === 'ONLINE' ? this.settingChannelCashInOnline :  this.settingChannelCashInCash,
        expiration_time: (item) === 'ONLINE' ? this.settingChannelCashInTimeOnline :  this.settingChannelCashInTimeCash,
        metadata: JSON.parse(sessionStorage.getItem('ud')).token
      }
    };
    return await this.api.api(data).toPromise();
  }

  updateChannelToggleSwitch(item) {
    if (item === 'ONLINE') {
      this.settingChannelCashInOnline = !this.settingChannelCashInOnline;
    } else {
      this.settingChannelCashInCash = !this.settingChannelCashInCash;
    }

    this.updateChannelSwitch(item);
  }

  updateChannelExpirationTime(item, event) {
    if (item === 'ONLINE') {
      this.settingChannelCashInTimeOnline = event.target.value;
    } else {
      this.settingChannelCashInTimeCash = event.target.value;
    }

    this.updateChannelSwitch(item);
  }

  async updateCurrencySwitch(currencyType, mode) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchant_currency',
      id: this.aR.snapshot.paramMap.get('id'),
      data : {
        type: (mode === 'CI') ? 1 : 2,
        currency_code: currencyType,
        max_amount : (mode === 'CI') ? 0 : this.validateMaxMode(currencyType),
        min_amount : (mode === 'CI') ? 200 : this.validateMinMode(currencyType),
        status : (mode === 'CI') ? this.validateCashInCurrency(currencyType) : this.validateCashOutCurrency(currencyType) ,
        metadata: JSON.parse(sessionStorage.getItem('ud')).token
      }
    };
    return await this.api.api(data).toPromise();
  }

  validateCashInCurrency(currencyType) {
    return (currencyType === 'PEN') ? this.settingCashInCurrencySoles : this.settingCashInCurrencyDolares;
  }

  validateCashOutCurrency(currencyType) {
    return (currencyType === 'PEN') ? this.settingCashOutCurrencySoles : this.settingCashOutCurrencyDolares;
  }

  validateMaxMode(currencyType) {
    return (currencyType === 'PEN') ? this.settingCashOutMaxamountSolesInitial : this.settingCashOutMaxamountDolaresInitial;
  }

  validateMinMode(currencyType) {
    return (currencyType === 'PEN') ? this.settingCashOutMinamountSolesInitial : this.settingCashOutMinamountDolaresInitial;
  }

  updateCashOutMaxCurrency(currencyType, event) {
    if (currencyType === 'PEN') {
      this.settingCashOutMaxamountSolesInitial = event.target.value;
    } else {
      this.settingCashOutMaxamountDolaresInitial = event.target.value;
    }
    this.updateCurrencySwitch(currencyType, 'CO');
  }

  updateCashOutMinCurrency(currencyType, event) {
    if (currencyType === 'PEN') {
      this.settingCashOutMinamountSolesInitial = event.target.value;
    } else {
      this.settingCashOutMinamountDolaresInitial = event.target.value;
    }
    this.updateCurrencySwitch(currencyType, 'CO');
  }

  updateCashInToogleSwicth(currencyType) {
    if (currencyType === 'PEN') {
      this.settingCashInCurrencySoles = !this.settingCashInCurrencySoles;
    } else {
      this.settingCashInCurrencyDolares = !this.settingCashInCurrencyDolares;
    }

    this.updateCurrencySwitch(currencyType, 'CI');
  }

  updateCashOutToogleSwicth(currencyType) {
    if (currencyType === 'PEN') {
      this.settingCashOutCurrencySoles = !this.settingCashOutCurrencySoles;
    } else {
      this.settingCashOutCurrencyDolares = !this.settingCashOutCurrencyDolares;
    }

    this.updateCurrencySwitch(currencyType, 'CO');
  }


  get legal_name() { return this.formForcePayment.get('legal_name'); }
  get country_code() { return this.formForcePayment.get('country_code'); }
  get bussines_name() { return this.formForcePayment.get('bussines_name'); }
  get legal_number() { return this.formForcePayment.get('legal_number'); }
  get url_web() { return this.formForcePayment.get('url_web'); }
  get merchant_type() { return this.formForcePayment.get('merchant_type'); }
  get color_1() { return this.formForcePayment.get('color_1'); }
  get color_2() { return this.formForcePayment.get('color_2'); }
  get color_3() { return this.formForcePayment.get('color_3'); }
  get user_fullname() { return this.formForcePayment.get('user_fullname'); }
  get user_email() { return this.formForcePayment.get('user_email'); }
  get user_mobile() { return this.formForcePayment.get('user_mobile'); }

  get active_cash_in() { return this.formSettings.get('active_cash_in'); }
  get active_cash_out() { return this.formSettings.get('active_cash_out'); }

  get setting_cash_in_check() { return this.formSettings.get('setting_cash_in_check'); }
  get setting_cash_out_check() { return this.formSettings.get('setting_cash_out_check'); }
  get setting_cash_in_time() { return this.formSettings.get('setting_cash_in_time'); }
  get setting_cash_out_time() { return this.formSettings.get('setting_cash_out_time'); }

  get setting_cash_in_currency_soles() { return this.formSettings.get('setting_cash_in_currency_soles'); }
  get setting_cash_in_currency_dolares() { return this.formSettings.get('setting_cash_in_currency_dolares'); }
  get setting_cash_out_currency_soles() { return this.formSettings.get('setting_cash_out_currency_soles'); }
  get setting_cash_out_currency_dolares() { return this.formSettings.get('setting_cash_out_currency_dolares'); }
  get setting_cash_out_maxAmount_soles() { return this.formSettings.get('setting_cash_out_maxAmount_soles'); }
  get setting_cash_out_minAmount_soles() { return this.formSettings.get('setting_cash_out_minAmount_soles'); }
  get setting_cash_out_maxAmount_dolares() { return this.formSettings.get('setting_cash_out_maxAmount_dolares'); }
  get setting_cash_out_minAmount_dolares() { return this.formSettings.get('setting_cash_out_minAmount_dolares'); }

  get staggeredFrom() { return this.formStaggered.get('staggeredFrom'); }
  get staggeredUntil() { return this.formStaggered.get('staggeredUntil'); }
  get staggeredPercentage() { return this.formStaggered.get('staggeredPercentage'); }
  get staggeredMinimum() { return this.formStaggered.get('staggeredMinimum'); }
  get staggeredMaximum() { return this.formStaggered.get('staggeredMaximum'); }
  get staggeredIVA() { return this.formStaggered.get('staggeredIVA'); }

  get bankType() { return this.formBankSection.get('bankType'); }
  get bankBank() { return this.formBankSection.get('bankBank'); }
  get bankAccountNumber() { return this.formBankSection.get('bankAccountNumber'); }
  get bankCurrency() { return this.formBankSection.get('bankCurrency'); }
  get bankState() { return this.formBankSection.get('bankState'); }


}
