import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-force-payments',
  templateUrl: './force-payments.component.html',
  styleUrls: ['./force-payments.component.scss']
})
export class ForcePaymentsComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash In > Forzar Pagos';

  titleModal:any = '';
  isVisible: boolean = false;
  contentModal: any = '';
  listBanksCashin: any;
  formForcePayment: FormGroup;
  messageModal: any = '';
  dataEventModal: any = '';
  dataForcePayment: any;
  okText: any = 'Pagar';
  isAlertVisible = false;


  constructor(private api: ApiService, private router: Router) {
    this.formForcePayment = this.createFormGroup();
  }

  createFormGroup() {
    return new FormGroup({
      bankCode: new FormControl('1001'),
      channel: new FormControl('1'),
      bankOperationNumber: new FormControl(''),
    });
  }
  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'forcePayment/listOperation',
      data: []
    };

    let headers = [
      {
        'name': 'Operación',
        'key': 'PublicId',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Código de pago',
        'key': 'PaymentCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Expiración',
        'key': 'TxExpiration',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'opc',
        'opc': false,
        'functions': ['Pagar'],
      },
    ];


    if (dataInfo !== false) {
      data.service = 'forcePayment/listOperationWithParams';
      data.data = dataInfo;
    }


    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'activities-cashin-forcepayment'
    }

  }



  async getFilters() {

    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    }
    let dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [

      {
        'name': 'N° Operación',
        'key': 'publicid',
        'type': 'text'
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },



    ];

  }

  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleAlertNotification(): void {
    this.isAlertVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formForcePayment = this.createFormGroup();
    var that = this;
    setTimeout(function() {
      that.isAlertVisible = false;
    }, 3000);
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case 'forcePayment':
        this.forcePayment().then((result: any) => {
          if (result.message === 'Payment successful') {
            this.handleAlertNotification();
          } else {
          }
          this.isVisible = false;
          this.okText = 'Aceptar';
        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        break;
      default:
        break;
    }

  }

  handleCancel(): void {
    this.isVisible = false;
  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    console.log(data);
    this.dataInfo = this.getDataInfo(data);

  }

  async getDataBankCashin(){
    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'listBanksCashin'
    }
    return await this.api.api(data).toPromise();
  }

  functions(f) {

    switch (f.function) {
      case 'Pagar':

        this.getDataBankCashin().then((result: any) => {
          this.listBanksCashin = result;
          this.titleModal = 'Forzar Pagos';
          this.contentModal = 'forcePayment';
          this.dataForcePayment = f;
          this.dataEventModal = 'forcePayment';
        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        this.showModal();
        this.okText = "Pagar";
        break;

      default:
        break;
    }

  }


  async forcePayment() {

    const data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'forcePayment',
      data: {
        bankCode: this.bankCode.value,
        channel: this.channel.value,
        publicId: this.dataForcePayment.data.PublicId,
        bankOperationNumber: this.bankOperationNumber.value,
        // token: JSON.parse(sessionStorage.setItem('ud')).token,
        metadata: JSON.stringify(this.dataForcePayment.userDetails),
      }
    };
    return await this.api.api(data).toPromise();
  }


  get bankCode() { return this.formForcePayment.get('bankCode'); }
  get channel() { return this.formForcePayment.get('channel'); }
  get bankOperationNumber() { return this.formForcePayment.get('bankOperationNumber'); }

}
