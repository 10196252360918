import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detail1',
  templateUrl: './detail1.component.html',
  styleUrls: ['./detail1.component.scss']
})
export class Detail1Component implements OnInit {

  @Input() data: any;

  headers: any;
  dataDetails: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.data.then((result: any) => {
      this.headers = result.headers;
      result.data.then((result: any) => {
        this.dataDetails = result;
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  getDataKeys(e, h) {
    console.log(h);

    if (e) {
      if (h.key.indexOf('/') > -1) {
        const keys = h.key.split('/');
        return e[keys[0].split('-')[0]][keys[0].split('-')[1]] + ' ' + e[keys[1].split('-')[0]][keys[1].split('-')[1]];
      } else {
        return e[h.key.split('-')[0]][h.key.split('-')[1]];
      }

    }
  }

}
