import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash In > Notificaciones';

  titleModal: any = 'Información';
  isVisible: boolean = false;
  contentModal: any = '';
  operationNumber: any = '';
  listBanksCashin: any;
  selectedNotifications:any = [];


  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'notification/listOperation',
      data: []
    }

    let headers = [
      {
        'name': 'Operación',
        'key': 'PublicId',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true
      },
      // {
      //   'name': 'Intentos',
      //   'key': 'attemps',
      //   'opc': true
      // },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      },
      {
        'name': 'Notificar',
        'key': 'opc',
        'opc': false,
        'functions': ['Notificar'],
      },
    ]

    if (dataInfo !== false) {
      data.service = 'notification/listOperationWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'activities-cashin-notifications'
    }

  }


  async getFilters() {

    let data = {
      token: JSON.parse(sessionStorage.getItem('ud')).token,
      service: 'merchants'
    }
    let dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [

      {
        'name': 'N° Operación',
        'key': 'publicid',
        'type': 'text'
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      }

    ];

  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    this.dataInfo = this.getDataInfo(data);
  }

  functions(e){
    switch (e.function) {
      case 'Notificar':
        this.selectNotifications(e);
        break;

      case 'NotificarTodo':
        this.selectNotificationsAll(e);
        break;

      case 'NotificarAction':
        console.log('NotificarAction');
        this.notification(e);
        break;
      default:
        break;
    }
  }

  searchByProp(e, PublicId){


    if(!e || e.length === 0){
      return -1;
    }else{
      for (let i = 0; i < e.length; i++) {
        if (e[i].PublicId === PublicId) {
          return i;
        } else {
          if (i == e.length - 1) {
            return -1;
          }
        }
      }
    }
  }


  selectNotifications(e){
    if(e.event.target.checked === false){
      const search = this.searchByProp(this.selectedNotifications, e.data.PublicId);
      if (search != -1){
        this.selectedNotifications.splice(search, 1);
      }
    }else{
      const search = this.searchByProp(this.selectedNotifications, e.data.PublicId);
      if (search == -1) {
        this.selectedNotifications.push(e.data);
      }
    }
  }



  selectNotificationsAll(e){

    var self = this;

    let ids = $('td input[type="checkbox"]');

    if(e.event.target.checked === false){
      $('td input[type="checkbox"]').prop('checked', false);
      self.selectedNotifications = [];
    }else{
      $('td input[type="checkbox"]').prop('checked', true);
      self.selectedNotifications = [];
      for (let i = 0; i < ids.length; i++) {
        const id = $(ids[i]).prop('id');
        const indexID = self.searchByProp(e.data, id);

        if(indexID != -1){
          self.selectedNotifications.push(e.data[indexID]);
        }

      }
    }
  }


  notification(e) {

    var self = this;
    this.selectedNotifications.forEach(element => {
      let data = {
        token: JSON.parse(sessionStorage.getItem('ud')).token,
        service: 'notification/execute/',
        data: element.PublicId
      }
      this.api.api(data).toPromise().then((result: any) => {

        self.contentModal = this.selectedNotifications.length + ' Notificaciones enviadas';
        self.showModal();

      }).catch(error => {
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
    });
  }

  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
    $('input[type="checkbox"]').prop('checked', false);
    this.selectedNotifications = [];
  }

  handleCancel(): void {
    this.isVisible = false;
  }







}
