import { Injectable } from "@angular/core";
import { HttpService } from "@services/http.service";

interface BagTypeModel {
  BagType: number;
}

@Injectable({
  providedIn: "root",
})
export class ProviderService {
  constructor(private http: HttpService) { }

  updateBagType(ProviderCode: number, data: BagTypeModel) {
    console.log("providerCode", ProviderCode, data);
    return this.http.post(`provider/${ProviderCode}/bag-type`, data);
  }
}
