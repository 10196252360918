import { CurrencyPipe } from "@angular/common";
import { MessageErrorService } from "./../../../../../services/utils/message-error.service";
import { BalanceService } from "./../../../../../services/balance/balance.service";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: "app-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.scss"],
})
export class BalanceComponent implements OnInit {
  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = "Actividades > Control Saldo > Aliados";
  public MerchantFinanceActivityId;

  titleModal: any = "";
  isVisible: boolean = false;
  modalDevolucion: boolean = false;
  contentModal: any = "";
  operationNumber: any = "";
  listBanksCashin: any;
  formNewBalance: FormGroup;
  formReturnBalance: FormGroup;
  okText: any = "";
  messageModal: string;
  dataEventModal: any;
  dataNewBalance: any;
  isAlertVisible = false;
  isAlertErrorVisible = false;
  dataMerchant: any;
  errorMessage = "";
  balanceCreate = false;
  dataBalanceCreate: any;
  MerchantFinanceActivityID: string;

  formattedAmount;
  amount;

  constructor(
    private api: ApiService,
    private router: Router,
    private balanceService: BalanceService,
    private notification: NzNotificationService,
    private messageError: MessageErrorService,
    private currencyPipe: CurrencyPipe
  ) {
    this.formNewBalance = this.createFormGroup();
    this.formReturnBalance = this.createFormReturnBalance();
  }

  ngOnInit() {}

  createFormGroup() {
    return new FormGroup({
      MerchantCode: new FormControl("1001"),
      BankCode: new FormControl("1001"),
      Reference: new FormControl(""),
      Amount: new FormControl(""),
      CurrencyId: new FormControl("USD"),
      Type: new FormControl("1"),
    });
  }

  createFormReturnBalance() {
    return new FormGroup({
      description: new FormControl("", [Validators.required]),
      metadata: new FormControl(
        JSON.stringify(JSON.parse(sessionStorage.getItem("ud")).userDetails),
        Validators.required
      ),
      currency_code: new FormControl("COP", Validators.required),
      is_partial: new FormControl(false),
      amount: new FormControl(""),
      reference: new FormControl("", Validators.required),
      merchant: new FormControl("", Validators.required),
    });
  }

  setAmountConditionalValidator() {
    this.formReturnBalance.get("is_partial").valueChanges.subscribe((value) => {
      if (value)
        this.formReturnBalance
          .get("amount")
          .setValidators([Validators.required]);
      else this.formReturnBalance.get("amount").setValidators(null);
    });
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "balance/listBalance",
      data: [],
    };

    const headers = [
      {
        name: "Number Account",
        key: "NumberAccount",
        opc: true,
      },
      {
        name: "Cliente",
        key: "BussinesName",
        opc: true,
      },
      {
        name: "Total Amount",
        key: "TotalAmount",
        opc: true,
      },
      {
        name: "Amount Available",
        key: "AmountAvailable",
        opc: true,
      },
      {
        name: "Max Amount",
        key: "MaxAmount",
        opc: true,
      },
      {
        name: "MinAmount",
        key: "MinAmount",
        opc: true,
      },
      {
        name: "Currency",
        key: "CurrencyCode",
        opc: true,
      },
      {
        name: "Active",
        key: "IsActive",
        opc: true,
      },
      {
        name: "Actividades",
        key: "actividadbalance",
        opc: true,
      },
    ];

    if (dataInfo !== false) {
      data.service = "balance/listBalanceWithParams";
      dataInfo.MerchantType = 6;
      data.data = dataInfo;
    }

    const getData = await this.api.api(data).toPromise();

    for (var i in getData) {
      getData[i].TotalAmount = new Intl.NumberFormat("en-US").format(
        getData[i].TotalAmount
      );
      getData[i].AmountAvailable = new Intl.NumberFormat("en-US").format(
        getData[i].AmountAvailable
      );
      getData[i].MaxAmount = new Intl.NumberFormat("en-US").format(
        getData[i].MaxAmount
      );
      getData[i].MinAmount = new Intl.NumberFormat("en-US").format(
        getData[i].MinAmount
      );
    }

    return {
      headers: headers,
      data: getData,
      source: "activities-cashout-balance",
    };
  }

  async getFilters() {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "merchants",
    };
    const dataMerchant = [];
    await this.api
      .api(data)
      .toPromise()
      .then((result: any) => {
        result.forEach((element) => {
          console.log("Element merchant", element);

          dataMerchant.push({
            name: element.BussinesName,
            value: element.MerchantCode,
            id: element.MerchantID,
          });
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
        }
      });

    const dataStatus = this.api.getStatus();
    this.dataMerchant = dataMerchant;

    return [
      {
        name: "Periodo",
        key: ["from_date", "to_date"],
        type: "period",
      },
      {
        name: "Referencia Comercial",
        key: "merchantSalesID",
        type: "text",
      },
      {
        name: "Comercio",
        key: "mcode",
        type: "select",
        data: dataMerchant,
      },
      // {
      //   name: "Nuevo balance",
      //   key: "new-balance",
      //   type: "button",
      // },
      {
        key: "actions",
        type: "actions",
        data: [
          {
            name: "Recargar",
            key: "new-balance",
            type: "button",
          },
          {
            name: "Devolucion",
            key: "new-refund",
            type: "button",
          },
        ],
      },
    ];
  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    switch (data) {
      case "new-balance":
        this.getDataBankCashin()
          .then((result: any) => {
            this.listBanksCashin = result;
            console.log(this.listBanksCashin);
            this.titleModal = "Nuevo Balance";
            this.contentModal = "newBalance";
            this.dataEventModal = "newBalance";
          })
          .catch((error) => {
            alert(error.message);
            if (error.status === 401) {
              sessionStorage.setItem("ud", "");
              this.router.navigate(["/"]);
            }
          });
        this.showModal();
        this.okText = "Crear";
        break;
      case "new-refund":
        this.getDataBankCashin()
          .then((result: any) => {
            this.listBanksCashin = result;
            console.log(this.listBanksCashin);
            this.titleModal = "Refund Balance";
            this.contentModal = "newReturnBalance";
            this.dataEventModal = "newReturnBalance";
          })
          .catch((error) => {
            alert(error.message);
            if (error.status === 401) {
              sessionStorage.setItem("ud", "");
              this.router.navigate(["/"]);
            }
          });
        this.showModalRefund();
        this.okText = "Crear";
        break;

      default:
        this.dataInfo = this.getDataInfo(data);
        break;
    }
  }

  functions(e) {
    console.log(e);
  }

  async getDataBankCashin() {
    let data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "listBanksCashin",
    };
    return await this.api.api(data).toPromise();
  }

  async newBalance() {
    const data = {
      token: JSON.parse(sessionStorage.getItem("ud")).token,
      service: "balance/create",
      data: {
        MerchantCode: this.MerchantCode.value,
        BankCode: this.BankCode.value,
        Reference: this.Reference.value,
        Amount: this.formNewBalance.controls.Amount.value.replace(/\,/g,""),
        CurrencyId: this.CurrencyId.value,
        Type: this.formNewBalance.get("Type").value,
        Metadata: JSON.stringify(
          JSON.parse(sessionStorage.getItem("ud")).userDetails
        ),
      },
    };
    return await this.api.api(data).toPromise();
  }

  getDetailCashoutOperation({ target }) {
    // if (target.value.length > 4) {
    this.balanceService.getDetailCashoutOperation(target.value).subscribe(
      (data: any) => {
        if (data) {
          this.MerchantFinanceActivityID = data.MerchantFinanceActivityID;
          this.formReturnBalance.get("BankCode").setValue(data.BankCode);
          this.formReturnBalance
            .get("MerchantCode")
            .setValue(data.MerchantCode);
          this.formReturnBalance.get("Amount").setValue(data.Amount);
          this.formReturnBalance.get("CurrencyId").setValue(data.CurrencyCode);
        }
      },
      (error) => {
        this.notification.create(
          "error",
          "Ha ocurrido un error",
          error.error.Message
        );
      }
    );
    // }
  }

  //MODAL

  showModal(): void {
    // console.log('hola ffff');
    this.isVisible = true;
  }

  showModalRefund(): void {
    this.modalDevolucion = true;
  }

  handleAlertNotification(): void {
    this.isAlertVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formNewBalance = this.createFormGroup();
    var that = this;
    setTimeout(function () {
      that.isAlertVisible = false;
    }, 3000);
  }

  handleAlertErrorNotification(): void {
    this.isAlertErrorVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formNewBalance = this.createFormGroup();
    var that = this;
    setTimeout(function () {
      that.isAlertErrorVisible = false;
      that.errorMessage = "";
    }, 3000);
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case "newBalance":
        this.newBalance()
          .then((result: any) => {
            this.dataBalanceCreate = result.data;

            if (result.message === "New balance updated successfull") {
              this.handleAlertNotification();
            } else {
              this.errorMessage = result.message;
              this.handleAlertErrorNotification();
            }
            this.balanceCreate = true;
            this.dataEventModal = "Aceptar";
            // this.isVisible = false;
            this.okText = "Aceptar";
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.handleAlertErrorNotification();
            console.log("ERROR", error);
            if (error.status === 401) {
              sessionStorage.setItem("ud", "");
              this.router.navigate(["/"]);
            }
          });
        break;

      case "Aceptar":
        console.log("Descargar PDF");
        this.isVisible = false;
        //Ejecutas tu funcion para descargar PDF
        this.dataBalanceCreate;
        break;
      case "newReturnBalance":
        if (!this.formReturnBalance.valid) {
          this.messageError.showValidationMsg(this.formReturnBalance);
          break;
        }
        this.formReturnBalance.controls.amount.setValue(this.formReturnBalance.controls.amount.value.replace(/\,/g, ""));
        this.balanceService
          .cashoutReturn(
            this.formReturnBalance.get("merchant").value,
            this.formReturnBalance.value
          )
          .subscribe(
            (response: any) => {
              this.notification.create("success", response.Message, "");
              this.dataInfo = this.getDataInfo();
              this.handleCancel();
            },
            (error) => {
              this.notification.create(
                "error",
                "Ha ocurrido un error",
                error.error.Message
              );
              this.dataInfo = this.getDataInfo();
            }
          );
        break;
      default:
        break;
    }
  }

  handleCancel(): void {
    this.formNewBalance = this.createFormGroup();
    this.balanceCreate = false;
    this.isVisible = false;
    this.modalDevolucion = false;
    this.formReturnBalance.reset();
    this.formReturnBalance = this.createFormReturnBalance();
  }

  get MerchantCode() {
    return this.formNewBalance.get("MerchantCode");
  }
  get BankCode() {
    return this.formNewBalance.get("BankCode");
  }
  get Reference() {
    return this.formNewBalance.get("Reference");
  }
  get Amount() {
    return this.formNewBalance.get("Amount");
  }
  get CurrencyId() {
    return this.formNewBalance.get("CurrencyId");
  }
}
