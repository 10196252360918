import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  response:string = '';
  public isLogged: boolean = false;
  formLogin: FormGroup;
  validateCaptcha = false

  constructor(private router: Router, private apiService: ApiService, private authService: AuthService) {
    this.formLogin = this.createFormGroup();
   }

  createFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  onLogin(): void {
    if (this.validateCaptcha) {
      let data = {
        email: this.formLogin.get('email').value,
        password: this.formLogin.get('password').value,
        service: 'auth/login'
      }


      this.authService.api(data).subscribe((result: any) => {
        console.log('result', result);
        if (result.p !== 'Error en credenciales') {
          sessionStorage.setItem('ud', JSON.stringify(result));
          
          this.router.navigate(['/']);
        } else   {
          this.response = JSON.stringify('Error en credenciales');
        }
      },
        error => {
          if (error.status === 401) {
            this.response = 'Error en el correo o contraseña';
          }
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        });
    }
  }


  ngOnInit() {
    this.verifySession();
  }

  verifySession() {
    if (sessionStorage.getItem('ud') !== '') {
      this.isLogged = true;
      this.router.navigate(['/dashboard']);
    }
  }

  onResetForm() {
    this.formLogin.reset();
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse) {
      this.validateCaptcha = true;
    }
  }

  get email() { return this.formLogin.get('email'); }
  get password() { return this.formLogin.get('password'); }


}
