import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import * as moment from "moment";
import { ApiService } from "@services/api.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-filter1",
  templateUrl: "./filter1.component.html",
  styleUrls: ["./filter1.component.scss"],
})
export class Filter1Component implements OnInit {
  @Input() data: any;
  @Output() dataOutput = new EventEmitter<any>();

  form: FormGroup;
  items: FormArray;
  dataFields: any;
  isVisible = false;
  modalRefund = false;
  date = null;
  dateRange = [];
  titleModal: any = "";
  buttonDownload = false;
  from_date = null;
  to_date = null;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      this.from_date = params.from_date;
      this.to_date = params.to_date;
    });

    this.data.then((data: any) => {
      this.dataFields = data;
      const item = {};

      data.forEach((e: any) => {
        if (e.key === "download") {
          this.buttonDownload = true;
        }

        let fromDate = this.from_date ? this.from_date :  moment().startOf("month").format("YYYY-MM-DD");
        let toDate = this.to_date ? this.to_date : moment().format("YYYY-MM-DD");
        console.log({
          fromDate,
          toDate
        });
        if (Array.isArray(e.key)) {
          item["period"] = "3";
          item[e.key[0]] = fromDate;
          item[e.key[1]] = toDate;
        } else {
          if (e.type === "select") {
            if(e.key === 'mcode')
              item[e.key] = e.data[0]['value'];
            else
              item[e.key] = "";
          } else {
            item[e.key] = "";
          }
        }
      });
      this.form = this.fb.group({
        items: this.fb.array([this.createItem(item)]),
      });
    });
  }

  onChangePeriod(e) {
    moment.locale("es", {
      week: {
        dow: 1,
        doy: 4,
      },
    });

    let fromDate = "";
    let toDate = moment().format("YYYY-MM-DD");

    switch (e.target.value) {
      case "1":
        fromDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        break;
      case "2":
        fromDate = moment().weekday(0).format("YYYY-MM-DD");
        break;
      case "3":
        fromDate = moment().startOf("month").format("YYYY-MM-DD");
        break;
      case "4":
        fromDate = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        break;
      case "5":
        this.showModal();
        break;
      case "6":
        this.showModaRefund();
        break;

      default:
        break;
    }

    const values = this.fields.controls[0].value;
    values.from_date = fromDate;
    values.to_date = toDate;

    if (e.target.value !== "5") {
      this.fields.controls[0].setValue(values);
    }
  }

  processAditionalButton(e) {
    this.dataOutput.emit(e);
  }

  processAditionalButtonDownload(e: any) {
    this.dataOutput.emit({
      type: e,
      data: this.getData(),
    });
  }

  process() {
    this.dataOutput.emit(this.getData());
  }

  getData() {
    let dataValues = (<FormArray>this.form.get("items")).controls[0].value;
    return dataValues;
  }

  createItem(item: any): FormGroup {
    return this.fb.group(item);
  }

  get formData() {
    return this.form ? (<FormArray>this.form.get("items")).controls : null;
  }

  get fields() {
    return this.form.get("items") as FormArray;
  }

  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  showModaRefund(): void {
    this.modalRefund = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  //DATE PICKER

  onChangeDate(result: Date): void {
    let fromDate = moment(result[0]).format("YYYY-MM-DD");
    let toDate = moment(result[1]).format("YYYY-MM-DD");
    let values = this.fields.controls[0].value;
    values.from_date = fromDate;
    values.to_date = toDate;
    this.fields.controls[0].setValue(values);
  }

  filterChange($event, item) {
    switch (item.name) {
      case "Proveedores":
        this.form.value.items[0].serviceCode = "";
        const dataServ = {
          token: JSON.parse(sessionStorage.getItem("ud")).token,
          service: `listProviderServices`,
          data: {
            providerCode: $event.target.value,
          },
        };
        const dataService = [];
        this.api
          .api(dataServ)
          .toPromise()
          .then((result: any) => {

            result.data.forEach((element) => {
              dataService.push({
                name: element.ServiceDescription,
                value: element.ServiceCode,
              });
            });

            this.dataFields = this.dataFields.map((dataF) => {
              if (dataF.name === "Servicio") {
                dataF.data = dataService;
              }
              return dataF;
            });
          })
          .catch((error) => {
            if (error.status === 401) {
              sessionStorage.setItem("ud", "");
              this.router.navigate(["/"]);
            }
          });
        break;

      case "Servicio":
        ///
        break;

      default:
        break;
    }
  }
}
