import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public isLogged = false;
  public path = '';

  constructor(private router: Router, private apiService: ApiService) { }

  ngOnInit() {
    this.verifySession();
    this.path = window.location.pathname.trim();
  }

  verifySession() {
    if (sessionStorage.getItem('ud') === '') {
      this.isLogged = true;
      this.router.navigate(['/login']);
    }
  }

}
